import { takeEvery, put, call } from "redux-saga/effects"

// Clients Redux States
import {
  ADD_NEW_CLIENT,
  DELETE_CLIENT,
  GET_CLIENTS,
  GET_CLIENT,
  UPDATE_CLIENT,
} from "./actionTypes"
import {
  getClientsSuccess,
  getClientsFail,
  getClientSuccess,
  getClientFail,
  addClientFail,
  addClientSuccess,
  updateClientSuccess,
  updateClientFail,
  deleteClientSuccess,
  deleteClientFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClients,
  getClient,
  addNewClient,
  updateClient,
  deleteClient,
} from "../../../helpers/api_back"

function* fetchClients() {
  try {
    const response = yield call(getClients)
    yield put(getClientsSuccess(response))
  } catch (error) {
    yield put(getClientsFail(error))
  }
}

function* fetchClient() {
  try {
    const response = yield call(getClient)
    yield put(getClientSuccess(response))
  } catch (error) {
    yield put(getClientFail(error))
  }
}


function* onAddNewClient({ payload: client }) {
 
  try {
    const response = yield call(addNewClient, client)
    
    yield put(addClientSuccess(response))
  } catch (error) {
    yield put(addClientFail(error))
  }
}

function* onUpdateClient({ payload: client }) {
  try {
    const response = yield call(updateClient, client)
    yield put(updateClientSuccess(response))
  } catch (error) {
    yield put(updateClientFail(error))
  }
}

function* onDeleteClient({ payload: client }) {
  try {
    const response = yield call(deleteClient, client)
    yield put(deleteClientSuccess(response))
  } catch (error) {
    yield put(deleteClientFail(error))
  }
}
 

function* clientsSaga() {
  yield takeEvery(GET_CLIENTS, fetchClients)
  yield takeEvery(GET_CLIENT, fetchClient)
  yield takeEvery(ADD_NEW_CLIENT, onAddNewClient)
  yield takeEvery(UPDATE_CLIENT, onUpdateClient)
  yield takeEvery(DELETE_CLIENT, onDeleteClient) 
}

export default clientsSaga
