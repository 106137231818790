import {
  GET_FACTUREDETAILSS_SUCCESS,
  GET_FACTUREDETAILSS_FAIL,
  GET_FACTUREDETAILS_SUCCESS,
  GET_FACTUREDETAILS_FAIL,
  ADD_FACTUREDETAILS_SUCCESS,
  ADD_FACTUREDETAILS_FAIL,
  UPDATE_FACTUREDETAILS_SUCCESS,
  UPDATE_FACTUREDETAILS_FAIL,
  DELETE_FACTUREDETAILS_SUCCESS,
  DELETE_FACTUREDETAILS_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  factureDetailss: [],
  error: {},
};

const FactureDetailss = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FACTUREDETAILSS_SUCCESS:
      return {
        ...state,
        factureDetailss: action.payload,
      };

    case GET_FACTUREDETAILSS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_FACTUREDETAILS_SUCCESS:
      return {
        ...state,
        factureDetails: action.payload,
      };
  
    case GET_FACTUREDETAILS_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_FACTUREDETAILS_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        factureDetailss: [...state.factureDetailss, action.payload],
      };

    case ADD_FACTUREDETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_FACTUREDETAILS_SUCCESS:
      return {
        ...state,
        factureDetailss: state.factureDetailss.map(factureDetails =>
          factureDetails.id.toString() === action.payload.id.toString()
            ? { factureDetails, ...action.payload }
            : factureDetails
        ),
        isFactureDetailsUpdated: true
      };

    case UPDATE_FACTUREDETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        isFactureDetailsUpdated: false
      };

    case DELETE_FACTUREDETAILS_SUCCESS:
      return {
        ...state,
        factureDetailss: state.factureDetailss.filter(
          factureDetails => factureDetails.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_FACTUREDETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default FactureDetailss;
