import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAIL,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  clients: [],
  error: {},
};

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };

    case GET_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload,
      };
  
    case GET_CLIENT_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_CLIENT_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        clients: [...state.clients, action.payload],
      };

    case ADD_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.map(client =>
          client.id.toString() === action.payload.id.toString()
            ? { client, ...action.payload }
            : client
        ),
        isClientUpdated: true
      };

    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isClientUpdated: false
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(
          client => client.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Clients;
