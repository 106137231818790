import {
  GET_FACTURES,
  GET_FACTURES_SUCCESS,
  GET_FACTURES_FAIL,
  GET_FACTURE,
  GET_FACTURE_FAIL,
  GET_FACTURE_SUCCESS,
  ADD_NEW_FACTURE,
  ADD_FACTURE_SUCCESS,
  ADD_FACTURE_FAIL,
  UPDATE_FACTURE,
  UPDATE_FACTURE_SUCCESS,
  UPDATE_FACTURE_FAIL,
  DELETE_FACTURE,
  DELETE_FACTURE_SUCCESS,
  DELETE_FACTURE_FAIL,
} from "./actionTypes";

export const getFactures = () => ({
  type: GET_FACTURES,
});

export const getFacturesSuccess = factures => ({
  type: GET_FACTURES_SUCCESS,
  payload: factures,
});

export const getFacturesFail = error => ({
  type: GET_FACTURES_FAIL,
  payload: error,
});

export const getFacture = () => ({
  type: GET_FACTURE,
});

export const getFactureSuccess = facture => ({
  type: GET_FACTURE_SUCCESS,
  payload: facture,
});

export const getFactureFail = error => ({
  type: GET_FACTURE_FAIL,
  payload: error,
});

export const addNewFacture = facture => ({
  type: ADD_NEW_FACTURE,
  payload: facture,
});

export const addFactureSuccess = facture => ({
  type: ADD_FACTURE_SUCCESS,
  payload: facture,
});

export const addFactureFail = error => ({
  type: ADD_FACTURE_FAIL,
  payload: error,
  
});

export const updateFacture = (cuv,facture) => ({
  type: UPDATE_FACTURE,
  payload: { id: cuv, data: facture },
} );

export const updateFactureSuccess = (cuv,facture) => ({
  type: UPDATE_FACTURE_SUCCESS,
  payload:  { id: cuv, data: facture }, 
});

export const updateFactureFail = error => ({
  type: UPDATE_FACTURE_FAIL,
  payload: error,
});

export const deleteFacture = facture => ({
  type: DELETE_FACTURE,
  payload: facture,
});

export const deleteFactureSuccess = facture => ({
  type: DELETE_FACTURE_SUCCESS,
  payload: facture,
});

export const deleteFactureFail = error => ({
  type: DELETE_FACTURE_FAIL,
  payload: error,
});



 
