import { takeEvery, put, call } from "redux-saga/effects"

// FactureDetailss Redux States
import {
  ADD_NEW_FACTUREDETAILS,
  DELETE_FACTUREDETAILS,
  GET_FACTUREDETAILSS,
  GET_FACTUREDETAILS,
  UPDATE_FACTUREDETAILS,
} from "./actionTypes"
import {
  getFactureDetailssSuccess,
  getFactureDetailssFail,
  getFactureDetailsSuccess,
  getFactureDetailsFail,
  addFactureDetailsFail,
  addFactureDetailsSuccess,
  updateFactureDetailsSuccess,
  updateFactureDetailsFail,
  deleteFactureDetailsSuccess,
  deleteFactureDetailsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFactureDetailss,
  getFactureDetails,
  addNewFactureDetails,
  updateFactureDetails,
  deleteFactureDetails,
} from "../../../helpers/api_back"

function* fetchFactureDetailss() {
  try {
    const response = yield call(getFactureDetailss)
    yield put(getFactureDetailssSuccess(response))
  } catch (error) {
    yield put(getFactureDetailssFail(error))
  }
}

function* fetchFactureDetails() {
  try {
    const response = yield call(getFactureDetails)
    yield put(getFactureDetailsSuccess(response))
  } catch (error) {
    yield put(getFactureDetailsFail(error))
  }
}


function* onAddNewFactureDetails({ payload: factureDetails }) {
 
  try {
    const response = yield call(addNewFactureDetails, factureDetails)
    
    yield put(addFactureDetailsSuccess(response))
  } catch (error) {
    yield put(addFactureDetailsFail(error))
  }
}

function* onUpdateFactureDetails({ payload: factureDetails }) {
  try {
    const response = yield call(updateFactureDetails, factureDetails)
    yield put(updateFactureDetailsSuccess(response))
  } catch (error) {
    yield put(updateFactureDetailsFail(error))
  }
}

function* onDeleteFactureDetails({ payload: factureDetails }) {
  try {
    const response = yield call(deleteFactureDetails, factureDetails)
    yield put(deleteFactureDetailsSuccess(response))
  } catch (error) {
    yield put(deleteFactureDetailsFail(error))
  }
}
 

function* factureDetailssSaga() {
  yield takeEvery(GET_FACTUREDETAILSS, fetchFactureDetailss)
  yield takeEvery(GET_FACTUREDETAILS, fetchFactureDetails)
  yield takeEvery(ADD_NEW_FACTUREDETAILS, onAddNewFactureDetails)
  yield takeEvery(UPDATE_FACTUREDETAILS, onUpdateFactureDetails)
  yield takeEvery(DELETE_FACTUREDETAILS, onDeleteFactureDetails) 
}

export default factureDetailssSaga
