/* PAIEMENTS */
export const GET_PAIEMENTS = "GET_PAIEMENTS";
export const GET_PAIEMENTS_SUCCESS = "GET_PAIEMENTS_SUCCESS";
export const GET_PAIEMENTS_FAIL = "GET_PAIEMENTS_FAIL";

export const GET_PAIEMENT = "GET_PAIEMENT";
export const GET_PAIEMENT_SUCCESS = "GET_PAIEMENT_SUCCESS";
export const GET_PAIEMENT_FAIL = "GET_PAIEMENT_FAIL";

export const ADD_NEW_PAIEMENT = "ADD_NEW_PAIEMENT";
export const ADD_PAIEMENT_SUCCESS = "ADD_PAIEMENT_SUCCESS";
export const ADD_PAIEMENT_FAIL = "ADD_PAIEMENT_FAIL";

export const UPDATE_PAIEMENT = "UPDATE_PAIEMENT";
export const UPDATE_PAIEMENT_SUCCESS = "UPDATE_PAIEMENT_SUCCESS";
export const UPDATE_PAIEMENT_FAIL = "UPDATE_PAIEMENT_FAIL";

export const DELETE_PAIEMENT = "DELETE_PAIEMENT";
export const DELETE_PAIEMENT_SUCCESS = "DELETE_PAIEMENT_SUCCESS";
export const DELETE_PAIEMENT_FAIL = "DELETE_PAIEMENT_FAIL";

 