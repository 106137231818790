import { takeEvery, put, call } from "redux-saga/effects"

// Paiements Redux States
import {
  ADD_NEW_PAIEMENT,
  DELETE_PAIEMENT,
  GET_PAIEMENTS,
  GET_PAIEMENT,
  UPDATE_PAIEMENT,
} from "./actionTypes"
import {
  getPaiementsSuccess,
  getPaiementsFail,
  getPaiementSuccess,
  getPaiementFail,
  addPaiementFail,
  addPaiementSuccess,
  updatePaiementSuccess,
  updatePaiementFail,
  deletePaiementSuccess,
  deletePaiementFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPaiements,
  getPaiement,
  addNewPaiement,
  updatePaiement,
  deletePaiement,
} from "../../../helpers/api_back"

function* fetchPaiements() {
  try {
    const response = yield call(getPaiements)
    yield put(getPaiementsSuccess(response))
  } catch (error) {
    yield put(getPaiementsFail(error))
  }
}

function* fetchPaiement() {
  try {
    const response = yield call(getPaiement)
    yield put(getPaiementSuccess(response))
  } catch (error) {
    yield put(getPaiementFail(error))
  }
}


function* onAddNewPaiement({ payload: paiement }) {
 
  try {
    const response = yield call(addNewPaiement, paiement)
    
    yield put(addPaiementSuccess(response))
  } catch (error) {
    yield put(addPaiementFail(error))
  }
}

function* onUpdatePaiement({ payload: paiement }) {
  try {
    const response = yield call(updatePaiement, paiement)
    yield put(updatePaiementSuccess(response))
  } catch (error) {
    yield put(updatePaiementFail(error))
  }
}

function* onDeletePaiement({ payload: paiement }) {
  try {
    const response = yield call(deletePaiement, paiement)
    yield put(deletePaiementSuccess(response))
  } catch (error) {
    yield put(deletePaiementFail(error))
  }
}
 

function* paiementsSaga() {
  yield takeEvery(GET_PAIEMENTS, fetchPaiements)
  yield takeEvery(GET_PAIEMENT, fetchPaiement)
  yield takeEvery(ADD_NEW_PAIEMENT, onAddNewPaiement)
  yield takeEvery(UPDATE_PAIEMENT, onUpdatePaiement)
  yield takeEvery(DELETE_PAIEMENT, onDeletePaiement) 
}

export default paiementsSaga
