import {
  GET_MODEPAIEMENTS_SUCCESS,
  GET_MODEPAIEMENTS_FAIL,
  GET_MODEPAIEMENT_SUCCESS,
  GET_MODEPAIEMENT_FAIL,
  ADD_MODEPAIEMENT_SUCCESS,
  ADD_MODEPAIEMENT_FAIL,
  UPDATE_MODEPAIEMENT_SUCCESS,
  UPDATE_MODEPAIEMENT_FAIL,
  DELETE_MODEPAIEMENT_SUCCESS,
  DELETE_MODEPAIEMENT_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  modePaiements: [],
  error: {},
};

const ModePaiements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MODEPAIEMENTS_SUCCESS:
      return {
        ...state,
        modePaiements: action.payload,
      };

    case GET_MODEPAIEMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_MODEPAIEMENT_SUCCESS:
      return {
        ...state,
        modePaiement: action.payload,
      };
  
    case GET_MODEPAIEMENT_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_MODEPAIEMENT_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        modePaiements: [...state.modePaiements, action.payload],
      };

    case ADD_MODEPAIEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_MODEPAIEMENT_SUCCESS:
      return {
        ...state,
        modePaiements: state.modePaiements.map(modePaiement =>
          modePaiement.id.toString() === action.payload.id.toString()
            ? { modePaiement, ...action.payload }
            : modePaiement
        ),
        isModePaiementUpdated: true
      };

    case UPDATE_MODEPAIEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isModePaiementUpdated: false
      };

    case DELETE_MODEPAIEMENT_SUCCESS:
      return {
        ...state,
        modePaiements: state.modePaiements.filter(
          modePaiement => modePaiement.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_MODEPAIEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default ModePaiements;
