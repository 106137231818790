import {
  GET_PAIEMENTS,
  GET_PAIEMENTS_SUCCESS,
  GET_PAIEMENTS_FAIL,
  GET_PAIEMENT,
  GET_PAIEMENT_FAIL,
  GET_PAIEMENT_SUCCESS,
  ADD_NEW_PAIEMENT,
  ADD_PAIEMENT_SUCCESS,
  ADD_PAIEMENT_FAIL,
  UPDATE_PAIEMENT,
  UPDATE_PAIEMENT_SUCCESS,
  UPDATE_PAIEMENT_FAIL,
  DELETE_PAIEMENT,
  DELETE_PAIEMENT_SUCCESS,
  DELETE_PAIEMENT_FAIL,
} from "./actionTypes";

export const getPaiements = () => ({
  type: GET_PAIEMENTS,
});

export const getPaiementsSuccess = paiements => ({
  type: GET_PAIEMENTS_SUCCESS,
  payload: paiements,
});

export const getPaiementsFail = error => ({
  type: GET_PAIEMENTS_FAIL,
  payload: error,
});

export const getPaiement = () => ({
  type: GET_PAIEMENT,
});

export const getPaiementSuccess = paiement => ({
  type: GET_PAIEMENT_SUCCESS,
  payload: paiement,
});

export const getPaiementFail = error => ({
  type: GET_PAIEMENT_FAIL,
  payload: error,
});

export const addNewPaiement = paiement => ({
  type: ADD_NEW_PAIEMENT,
  payload: paiement,
});

export const addPaiementSuccess = paiement => ({
  type: ADD_PAIEMENT_SUCCESS,
  payload: paiement,
});

export const addPaiementFail = error => ({
  type: ADD_PAIEMENT_FAIL,
  payload: error,
  
});

export const updatePaiement = (cuv,paiement) => ({
  type: UPDATE_PAIEMENT,
  payload: { id: cuv, data: paiement },
} );

export const updatePaiementSuccess = (cuv,paiement) => ({
  type: UPDATE_PAIEMENT_SUCCESS,
  payload:  { id: cuv, data: paiement }, 
});

export const updatePaiementFail = error => ({
  type: UPDATE_PAIEMENT_FAIL,
  payload: error,
});

export const deletePaiement = paiement => ({
  type: DELETE_PAIEMENT,
  payload: paiement,
});

export const deletePaiementSuccess = paiement => ({
  type: DELETE_PAIEMENT_SUCCESS,
  payload: paiement,
});

export const deletePaiementFail = error => ({
  type: DELETE_PAIEMENT_FAIL,
  payload: error,
});



 
