/* MODEPAIEMENTS */
export const GET_MODEPAIEMENTS = "GET_MODEPAIEMENTS";
export const GET_MODEPAIEMENTS_SUCCESS = "GET_MODEPAIEMENTS_SUCCESS";
export const GET_MODEPAIEMENTS_FAIL = "GET_MODEPAIEMENTS_FAIL";

export const GET_MODEPAIEMENT = "GET_MODEPAIEMENT";
export const GET_MODEPAIEMENT_SUCCESS = "GET_MODEPAIEMENT_SUCCESS";
export const GET_MODEPAIEMENT_FAIL = "GET_MODEPAIEMENT_FAIL";

export const ADD_NEW_MODEPAIEMENT = "ADD_NEW_MODEPAIEMENT";
export const ADD_MODEPAIEMENT_SUCCESS = "ADD_MODEPAIEMENT_SUCCESS";
export const ADD_MODEPAIEMENT_FAIL = "ADD_MODEPAIEMENT_FAIL";

export const UPDATE_MODEPAIEMENT = "UPDATE_MODEPAIEMENT";
export const UPDATE_MODEPAIEMENT_SUCCESS = "UPDATE_MODEPAIEMENT_SUCCESS";
export const UPDATE_MODEPAIEMENT_FAIL = "UPDATE_MODEPAIEMENT_FAIL";

export const DELETE_MODEPAIEMENT = "DELETE_MODEPAIEMENT";
export const DELETE_MODEPAIEMENT_SUCCESS = "DELETE_MODEPAIEMENT_SUCCESS";
export const DELETE_MODEPAIEMENT_FAIL = "DELETE_MODEPAIEMENT_FAIL";

 