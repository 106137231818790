//REGISTER
export const POST_USER_REGISTER = "/api/auth/signup";

//LOGIN
export const POST_USER_LOGIN = "/api/auth/signin";
export const POST_USER_JWT_LOGIN = "/api/auth/signin";
export const POST_USER_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_USER_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//USERS
export const GET_USERS = "/api/users/";
export const ADD_USER = "/api/users/";
export const UPDATE_USER = "/api/users/";
export const DELETE_USER = "/api/users/";
export const GET_USER = "/api/users/";


//CATEGORIES
export const GET_CATEGORIES = "/api/categories/";
export const ADD_CATEGORIE = "/api/categories/";
export const UPDATE_CATEGORIE = "/api/categories/";
export const DELETE_CATEGORIE = "/api/categories/";
export const GET_CATEGORIE = "/api/categories/";


//MOUVEMENTSTOCK
export const GET_MOUVEMENTSTOCKS = "/api/mouvementStocks/";
export const ADD_MOUVEMENTSTOCK = "/api/mouvementStocks/";
export const UPDATE_MOUVEMENTSTOCK = "/api/mouvementStocks/";
export const DELETE_MOUVEMENTSTOCK = "/api/mouvementStocks/";
export const GET_MOUVEMENTSTOCK = "/api/mouvementStocks/";

//PRODUITS
export const GET_PRODUITS = "/api/produits/";
export const ADD_PRODUIT = "/api/produits/";
export const UPDATE_PRODUIT = "/api/produits/";
export const DELETE_PRODUIT = "/api/produits/";
export const GET_PRODUIT = "/api/produits/";


//FOURNISSEURS
export const GET_FOURNISSEURS = "/api/fournisseurs/";
export const ADD_FOURNISSEUR = "/api/fournisseurs/";
export const UPDATE_FOURNISSEUR = "/api/fournisseurs/";
export const DELETE_FOURNISSEUR = "/api/fournisseurs/";
export const GET_FOURNISSEUR = "/api/fournisseurs/";


//APPROVISIONNEMENTS
export const GET_APPROS = "/api/appro/";
export const ADD_APPRO = "/api/appro/";
export const UPDATE_APPRO = "/api/appro/";
export const DELETE_APPRO = "/api/appro/";
export const GET_APPRO = "/api/appro/";


//CUVES
export const GET_CUVES = "/api/cuves/";
export const ADD_CUVE = "/api/cuves/";
export const UPDATE_CUVE= "/api/cuves/";
export const DELETE_CUVE = "/api/cuves/";
export const GET_CUVE = "/api/cuves/";


//DETAILS CUVES
export const GET_DETAIL_CUVES = "/api/detailCuves/"; 
export const ADD_DETAIL_CUVE = "/api/detailCuves/";
export const UPDATE_DETAIL_CUVE= "/api/detailCuves/";
export const DELETE_DETAIL_CUVE = "/api/detailCuves/";
export const GET_DETAIL_CUVE = "/api/detailCuves/";


//POMPES
export const GET_POMPES= "/api/pompes/";
export const ADD_POMPE = "/api/pompes/";
export const UPDATE_POMPE = "/api/pompes/";
export const DELETE_POMPE = "/api/pompes/";
export const GET_POMPE = "/api/pompes/";



//PRIX
export const GET_ALL_PRIX = "/api/prix/";
export const ADD_PRIX = "/api/prix/";
export const UPDATE_PRIX = "/api/prix/";
export const DELETE_PRIX = "/api/prix/";
export const GET_PRIX = "/api/prix/";


//STOCK
export const GET_STOCKS = "/api/stocks/";
export const ADD_STOCK = "/api/stocks/";
export const UPDATE_STOCK = "/api/stocks/";
export const DELETE_STOCK = "/api/stocks/";
export const GET_STOCK = "/api/stocks/";



//AGENTS
export const GET_AGENTS = "/api/agents/";
export const ADD_AGENT = "/api/agents/";
export const UPDATE_AGENT = "/api/agents/";
export const DELETE_AGENT = "/api/agents/";
export const GET_AGENT = "/api/agents/";


//SALAIRE
export const GET_SALAIRES = "/api/salaires/";
export const ADD_SALAIRE = "/api/salaires/";
export const UPDATE_SALAIRE = "/api/salaires/";
export const DELETE_SALAIRE = "/api/salaires/";
export const GET_SALAIRE = "/api/salaires/";


//EQUIPE
export const GET_EQUIPES = "/api/equipes/";
export const ADD_EQUIPE = "/api/equipes/";
export const UPDATE_EQUIPE = "/api/equipes/";
export const DELETE_EQUIPE = "/api/equipes/";
export const GET_EQUIPE = "/api/equipes/";


//CATEGORIES DEPENSES
export const GET_DEPENSE_CATEGORIES = "/api/depenseCategories/";
export const ADD_DEPENSE_CATEGORIE = "/api/depenseCategories/";
export const UPDATE_DEPENSE_CATEGORIE = "/api/depenseCategories/";
export const DELETE_DEPENSE_CATEGORIE = "/api/depenseCategories/";
export const GET_DEPENSE_CATEGORIE = "/api/depenseCategories/";

//SOUS CATEGORIES DEPENSES
export const GET_DEPENSE_SOUSCATEGORIES = "/api/depenseSousCategories/";
export const ADD_DEPENSE_SOUSCATEGORIE = "/api/depenseSousCategories/";
export const UPDATE_DEPENSE_SOUSCATEGORIE = "/api/depenseSousCategories/";
export const DELETE_DEPENSE_SOUSCATEGORIE = "/api/depenseSousCategories/";
export const GET_DEPENSE_SOUSCATEGORIE = "/api/depenseSousCategories/";



//DEPENSES
export const GET_DEPENSES = "/api/depenses/";
export const ADD_DEPENSE = "/api/depenses/";
export const UPDATE_DEPENSE = "/api/depenses/";
export const DELETE_DEPENSE = "/api/depenses/";
export const GET_DEPENSE = "/api/depenses/";

//DEMANDE
export const GET_DEMANDES = "/api/demandes/";
export const ADD_DEMANDE = "/api/demandes/";
export const UPDATE_DEMANDE = "/api/demandes/";
export const DELETE_DEMANDE = "/api/demandes/";
export const GET_DEMANDE = "/api/demandes/";


//TYPE DEMANDE
export const GET_TYPEDEMANDES = "/api/typeDemandes/";
export const ADD_TYPEDEMANDE = "/api/typeDemandes/";
export const UPDATE_TYPEDEMANDE = "/api/typeDemandes/";
export const DELETE_TYPEDEMANDE = "/api/typeDemandes/";
export const GET_TYPEDEMANDE = "/api/typeDemandes/";

//MODEREMBOURSEMENT
export const GET_MODEREMBOURSEMENTS = "/api/modeRemboursements/";
export const ADD_MODEREMBOURSEMENT = "/api/modeRemboursements/";
export const UPDATE_MODEREMBOURSEMENT = "/api/modeRemboursements/";
export const DELETE_MODEREMBOURSEMENT = "/api/modeRemboursements/";
export const GET_MODEREMBOURSEMENT = "/api/modeRemboursements/";




//VENTES
export const GET_VENTES = "/api/ventes/";
export const ADD_VENTE = "/api/ventes/";
export const UPDATE_VENTE = "/api/ventes/";
export const DELETE_VENTE = "/api/ventes/";
export const GET_VENTE = "/api/ventes/";

//FACTURATION 
export const GET_CLIENTS = "/api/clients/";
export const ADD_CLIENT = "/api/clients/";
export const UPDATE_CLIENT = "/api/clients/";
export const DELETE_CLIENT = "/api/clients/";
export const GET_CLIENT = "/api/clients/";

export const GET_FACTURES = "/api/factures/";
export const ADD_FACTURE = "/api/factures/";
export const UPDATE_FACTURE = "/api/factures/";
export const DELETE_FACTURE = "/api/factures/";
export const GET_FACTURE = "/api/factures/";

export const GET_FACTUREDETAILSS = "/api/factureDetails/";
export const ADD_FACTUREDETAILS = "/api/factureDetails/";
export const UPDATE_FACTUREDETAILS = "/api/factureDetails/";
export const DELETE_FACTUREDETAILS = "/api/factureDetails/";
export const GET_FACTUREDETAILS = "/api/factureDetails/";

export const GET_PAIEMENTS = "/api/paiements/";
export const ADD_PAIEMENT = "/api/paiements/";
export const UPDATE_PAIEMENT = "/api/paiements/";
export const DELETE_PAIEMENT = "/api/paiements/";
export const GET_PAIEMENT = "/api/paiements/";

export const GET_MODEPAIEMENTS = "/api/modePaiements/";
export const ADD_MODEPAIEMENT = "/api/modePaiements/";
export const UPDATE_MODEPAIEMENT = "/api/modePaiements/";
export const DELETE_MODEPAIEMENT = "/api/modePaiements/";
export const GET_MODEPAIEMENT = "/api/modePaiements/";

//FILES
export const GET_FILES = "/api/files/";
export const ADD_FILE = "/api/files/";
export const UPDATE_FILE = "/api/files/";
export const DELETE_FILE = "/api/files/";
export const GET_FILE = "/api/files/";