import { takeEvery, put, call } from "redux-saga/effects"

// Factures Redux States
import {
  ADD_NEW_FACTURE,
  DELETE_FACTURE,
  GET_FACTURES,
  GET_FACTURE,
  UPDATE_FACTURE,
} from "./actionTypes"
import {
  getFacturesSuccess,
  getFacturesFail,
  getFactureSuccess,
  getFactureFail,
  addFactureFail,
  addFactureSuccess,
  updateFactureSuccess,
  updateFactureFail,
  deleteFactureSuccess,
  deleteFactureFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFactures,
  getFacture,
  addNewFacture,
  updateFacture,
  deleteFacture,
} from "../../../helpers/api_back"

function* fetchFactures() {
  try {
    const response = yield call(getFactures)
    yield put(getFacturesSuccess(response))
  } catch (error) {
    yield put(getFacturesFail(error))
  }
}

function* fetchFacture() {
  try {
    const response = yield call(getFacture)
    yield put(getFactureSuccess(response))
  } catch (error) {
    yield put(getFactureFail(error))
  }
}


function* onAddNewFacture({ payload: facture }) {
 
  try {
    const response = yield call(addNewFacture, facture)
    
    yield put(addFactureSuccess(response))
  } catch (error) {
    yield put(addFactureFail(error))
  }
}

function* onUpdateFacture({ payload: facture }) {
  try {
    const response = yield call(updateFacture, facture)
    yield put(updateFactureSuccess(response))
  } catch (error) {
    yield put(updateFactureFail(error))
  }
}

function* onDeleteFacture({ payload: facture }) {
  try {
    const response = yield call(deleteFacture, facture)
    yield put(deleteFactureSuccess(response))
  } catch (error) {
    yield put(deleteFactureFail(error))
  }
}
 

function* facturesSaga() {
  yield takeEvery(GET_FACTURES, fetchFactures)
  yield takeEvery(GET_FACTURE, fetchFacture)
  yield takeEvery(ADD_NEW_FACTURE, onAddNewFacture)
  yield takeEvery(UPDATE_FACTURE, onUpdateFacture)
  yield takeEvery(DELETE_FACTURE, onDeleteFacture) 
}

export default facturesSaga
