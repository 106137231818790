import {
  GET_FACTUREDETAILSS,
  GET_FACTUREDETAILSS_SUCCESS,
  GET_FACTUREDETAILSS_FAIL,
  GET_FACTUREDETAILS,
  GET_FACTUREDETAILS_FAIL,
  GET_FACTUREDETAILS_SUCCESS,
  ADD_NEW_FACTUREDETAILS,
  ADD_FACTUREDETAILS_SUCCESS,
  ADD_FACTUREDETAILS_FAIL,
  UPDATE_FACTUREDETAILS,
  UPDATE_FACTUREDETAILS_SUCCESS,
  UPDATE_FACTUREDETAILS_FAIL,
  DELETE_FACTUREDETAILS,
  DELETE_FACTUREDETAILS_SUCCESS,
  DELETE_FACTUREDETAILS_FAIL,
} from "./actionTypes";

export const getFactureDetailss = () => ({
  type: GET_FACTUREDETAILSS,
});

export const getFactureDetailssSuccess = factureDetailss => ({
  type: GET_FACTUREDETAILSS_SUCCESS,
  payload: factureDetailss,
});

export const getFactureDetailssFail = error => ({
  type: GET_FACTUREDETAILSS_FAIL,
  payload: error,
});

export const getFactureDetails = () => ({
  type: GET_FACTUREDETAILS,
});

export const getFactureDetailsSuccess = factureDetails => ({
  type: GET_FACTUREDETAILS_SUCCESS,
  payload: factureDetails,
});

export const getFactureDetailsFail = error => ({
  type: GET_FACTUREDETAILS_FAIL,
  payload: error,
});

export const addNewFactureDetails = factureDetails => ({
  type: ADD_NEW_FACTUREDETAILS,
  payload: factureDetails,
});

export const addFactureDetailsSuccess = factureDetails => ({
  type: ADD_FACTUREDETAILS_SUCCESS,
  payload: factureDetails,
});

export const addFactureDetailsFail = error => ({
  type: ADD_FACTUREDETAILS_FAIL,
  payload: error,
  
});

export const updateFactureDetails = (cuv,factureDetails) => ({
  type: UPDATE_FACTUREDETAILS,
  payload: { id: cuv, data: factureDetails },
} );

export const updateFactureDetailsSuccess = (cuv,factureDetails) => ({
  type: UPDATE_FACTUREDETAILS_SUCCESS,
  payload:  { id: cuv, data: factureDetails }, 
});

export const updateFactureDetailsFail = error => ({
  type: UPDATE_FACTUREDETAILS_FAIL,
  payload: error,
});

export const deleteFactureDetails = factureDetails => ({
  type: DELETE_FACTUREDETAILS,
  payload: factureDetails,
});

export const deleteFactureDetailsSuccess = factureDetails => ({
  type: DELETE_FACTUREDETAILS_SUCCESS,
  payload: factureDetails,
});

export const deleteFactureDetailsFail = error => ({
  type: DELETE_FACTUREDETAILS_FAIL,
  payload: error,
});



 
