import {
  GET_MODEPAIEMENTS,
  GET_MODEPAIEMENTS_SUCCESS,
  GET_MODEPAIEMENTS_FAIL,
  GET_MODEPAIEMENT,
  GET_MODEPAIEMENT_FAIL,
  GET_MODEPAIEMENT_SUCCESS,
  ADD_NEW_MODEPAIEMENT,
  ADD_MODEPAIEMENT_SUCCESS,
  ADD_MODEPAIEMENT_FAIL,
  UPDATE_MODEPAIEMENT,
  UPDATE_MODEPAIEMENT_SUCCESS,
  UPDATE_MODEPAIEMENT_FAIL,
  DELETE_MODEPAIEMENT,
  DELETE_MODEPAIEMENT_SUCCESS,
  DELETE_MODEPAIEMENT_FAIL,
} from "./actionTypes";

export const getModePaiements = () => ({
  type: GET_MODEPAIEMENTS,
});

export const getModePaiementsSuccess = modePaiements => ({
  type: GET_MODEPAIEMENTS_SUCCESS,
  payload: modePaiements,
});

export const getModePaiementsFail = error => ({
  type: GET_MODEPAIEMENTS_FAIL,
  payload: error,
});

export const getModePaiement = () => ({
  type: GET_MODEPAIEMENT,
});

export const getModePaiementSuccess = modePaiement => ({
  type: GET_MODEPAIEMENT_SUCCESS,
  payload: modePaiement,
});

export const getModePaiementFail = error => ({
  type: GET_MODEPAIEMENT_FAIL,
  payload: error,
});

export const addNewModePaiement = modePaiement => ({
  type: ADD_NEW_MODEPAIEMENT,
  payload: modePaiement,
});

export const addModePaiementSuccess = modePaiement => ({
  type: ADD_MODEPAIEMENT_SUCCESS,
  payload: modePaiement,
});

export const addModePaiementFail = error => ({
  type: ADD_MODEPAIEMENT_FAIL,
  payload: error,
  
});

export const updateModePaiement = (cuv,modePaiement) => ({
  type: UPDATE_MODEPAIEMENT,
  payload: { id: cuv, data: modePaiement },
} );

export const updateModePaiementSuccess = (cuv,modePaiement) => ({
  type: UPDATE_MODEPAIEMENT_SUCCESS,
  payload:  { id: cuv, data: modePaiement }, 
});

export const updateModePaiementFail = error => ({
  type: UPDATE_MODEPAIEMENT_FAIL,
  payload: error,
});

export const deleteModePaiement = modePaiement => ({
  type: DELETE_MODEPAIEMENT,
  payload: modePaiement,
});

export const deleteModePaiementSuccess = modePaiement => ({
  type: DELETE_MODEPAIEMENT_SUCCESS,
  payload: modePaiement,
});

export const deleteModePaiementFail = error => ({
  type: DELETE_MODEPAIEMENT_FAIL,
  payload: error,
});



 
