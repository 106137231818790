import React, { useState, useEffect } from 'react'; 
import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //produits
    getProduits as onGetProduits,

    //paiements
    addNewPaiement as onAddNewPaiement,
    deletePaiement as onDeletePaiement,
    getPaiements as onGetPaiements,
    getPaiement as onGetPaiement,
    updatePaiement as onUpdatePaiement,
  } from "../../../store/actions"; 

  
 
const Paiements = () => {
const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();
 //console.log("Document",Document)
   
const produits = useSelector((state) => ({  produits: state.produits.produits  }));
const paiements = useSelector((state) => ({  paiements:  state.paiements.paiements  }));
const users = useSelector((state) => ({  users:  state.users.users  }));


const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };




function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 12000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'une paiement
const [libelle, setLibelle] = useState('');
const [produit, setProduit] = useState('');

const [idPaiement, setIdPaiement] = useState('');

const newPaiement = {
    libelle: libelle,
    produit:produit,  
};

const saveNewPaiement = (e) => {
    e.preventDefault(); 
    setTypMsg('add')
    dispatch(onAddNewPaiement(newPaiement));
    
    setmodal_list(false);
    
 
  };

  // Mise a jour d"une Paiement 
  const upPaiement = { 
    libelle: libelle, 
    produit:produit, 
  };

  const saveUpdatePaiement= (e) => {
    e.preventDefault();  
    dispatch(onUpdatePaiement(idPaiement,upPaiement));
    setTypMsg('upd') 
    setmodal_update(false);
    
  };


  /// Suppression d'une paiement
 

  const [listIdPaiement, setListIdPaiement] = useState([]);

  const idPaiementChecBoxSelected = (paiementId) => {
    setListIdPaiement(prevSelectedRows => ({
      ...prevSelectedRows,
      [paiementId]: !prevSelectedRows[paiementId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdPaiement)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const paiementIds = Object.keys(filteredList);
 
  const deletePaiement = ( paiement) => { 
    if(paiement)dispatch(onDeletePaiement(paiement));
    setTypMsg('del')
    if(listIdPaiement && paiementIds){paiementIds.forEach((paiementId) =>dispatch(onDeletePaiement(paiementId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(paiements.paiements);


const dataTable= filteredData.length!==0 ? 
filteredData.map(paiement => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    //console.log('xcate',categorie)
    const user = Object.values(users.users).find(us => us.id === paiement.createdBy);
    const produit = Object.values(produits.produits).find(prod => prod.id === paiement.produit);
    return { ...paiement , user: user? user.prenom+' '+user.nom :'',produitInfo:produit? produit.nomProd:''  };
  }) 
  : 
  paiements.paiements.map(paiement => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    const user = Object.values(users.users).find(us => us.id === paiement.createdBy);
    const produit = Object.values(produits.produits).find(prod => prod.id === paiement.produit);
    return { ...paiement,user: user? user.prenom+' '+user.nom :'' ,produitInfo:produit? produit.nomProd:'' };
  });
//,categorieProd:categorie.libelle

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;

const handleSearch = (paiement) => {
    const value = paiement.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.libelle), _.toLower(value))||
      _.startsWith(_.toLower(item.produit), _.toLower(value))|| 
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (paiement, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


 
const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((paiement,index) =>  (
    <tr key={paiement.id}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idPaiementChecBoxSelected(paiement.id)}}   />
        </div>
    </th>
    <td className="paiement_id" style={{ display: "none" }}>{paiement.id}</td>
    <td className="paiement_index">{index+1}</td>
    <td className="libelle">{paiement.libelle}</td> 
    <td className="produit">{paiement.produitInfo}</td>
    <td className="paiement_date">{ paiement.user}</td>
    <td className="paiement_utilisateur">{formatedDate(paiement.createdAt)}</td>
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setLibelle(paiement.libelle);setProduit(paiement.produit);setIdPaiement(paiement.id);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deletePaiement(paiement.id)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


));

   useEffect(() => { 
        
        dispatch(onGetPaiements());
        dispatch(onGetUsers());
        dispatch(onGetProduits());
        setFilteredData(paiements.paiements)
         
    },[dispatch,typMsg,onAddNewPaiement,onUpdatePaiement,onDeletePaiement]); 

   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Produit" breadcrumbItem="Liste des produits" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deletePaiement('');setListIdPaiement('')}}            
                                                    // onClick="deleteMultiple()"
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                </div> 
                                            </Col>
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="paiement_id" onClick={(paiement) => handleSort(paiement, 'id')}>N°</th>
                                                        <th className="sort" data-sort="libelle" onClick={(paiement) => handleSort(paiement, 'libelle')}>Libelle</th>
                                                        <th className="sort" data-sort="produit" onClick={(paiement) => handleSort(paiement, 'produit')}>Produit</th> 
                                                        <th className="sort" data-sort="createdBy" onClick={(paiement) => handleSort(paiement, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(paiement) => handleSort(paiement, 'createdAt')}>Date Création</th>
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Paiement </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewPaiement}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="libelle" className="form-label">Libelle</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  onChange={(e) => setLibelle(e.target.value)}/>
                            <label htmlFor="produit" className="form-label">Produit</label> 
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setProduit(e.target.value)}  defaultValue="0">
                                <option value="">Sélectionnez une paiement</option> {/* Option vide par défaut */}
                                {Object.values(produits.produits).map((prod) => (
                                    <option key={prod.id} value={prod.id}>
                                    {prod.nomProd}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>

                        </div>  
                         
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewPaiement}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Paiement </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdatePaiement}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="libelle" className="form-label">libelle</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  value={libelle} onChange={(event) => setLibelle(event.target.value)}/>
                            <label htmlFor="produit" className="form-label">Produit</label>
                            
                            <InputGroup>
                                <Input type="select" value={produit} onChange={(e) => setProduit(e.target.value)}  >
                                <option value="">Sélectionnez une paiement</option> {/* Option vide par défaut */}
                                {Object.values(produits.produits).map((prod) => (
                                    <option key={prod.id} value={prod.id}>
                                    {prod.nomProd}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                           
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdatePaiement}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Paiements;
