import { takeEvery, put, call } from "redux-saga/effects"

// ModePaiements Redux States
import {
  ADD_NEW_MODEPAIEMENT,
  DELETE_MODEPAIEMENT,
  GET_MODEPAIEMENTS,
  GET_MODEPAIEMENT,
  UPDATE_MODEPAIEMENT,
} from "./actionTypes"
import {
  getModePaiementsSuccess,
  getModePaiementsFail,
  getModePaiementSuccess,
  getModePaiementFail,
  addModePaiementFail,
  addModePaiementSuccess,
  updateModePaiementSuccess,
  updateModePaiementFail,
  deleteModePaiementSuccess,
  deleteModePaiementFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getModePaiements,
  getModePaiement,
  addNewModePaiement,
  updateModePaiement,
  deleteModePaiement,
} from "../../../helpers/api_back"

function* fetchModePaiements() {
  try {
    const response = yield call(getModePaiements)
    yield put(getModePaiementsSuccess(response))
  } catch (error) {
    yield put(getModePaiementsFail(error))
  }
}

function* fetchModePaiement() {
  try {
    const response = yield call(getModePaiement)
    yield put(getModePaiementSuccess(response))
  } catch (error) {
    yield put(getModePaiementFail(error))
  }
}


function* onAddNewModePaiement({ payload: modePaiement }) {
 
  try {
    const response = yield call(addNewModePaiement, modePaiement)
    
    yield put(addModePaiementSuccess(response))
  } catch (error) {
    yield put(addModePaiementFail(error))
  }
}

function* onUpdateModePaiement({ payload: modePaiement }) {
  try {
    const response = yield call(updateModePaiement, modePaiement)
    yield put(updateModePaiementSuccess(response))
  } catch (error) {
    yield put(updateModePaiementFail(error))
  }
}

function* onDeleteModePaiement({ payload: modePaiement }) {
  try {
    const response = yield call(deleteModePaiement, modePaiement)
    yield put(deleteModePaiementSuccess(response))
  } catch (error) {
    yield put(deleteModePaiementFail(error))
  }
}
 

function* modePaiementsSaga() {
  yield takeEvery(GET_MODEPAIEMENTS, fetchModePaiements)
  yield takeEvery(GET_MODEPAIEMENT, fetchModePaiement)
  yield takeEvery(ADD_NEW_MODEPAIEMENT, onAddNewModePaiement)
  yield takeEvery(UPDATE_MODEPAIEMENT, onUpdateModePaiement)
  yield takeEvery(DELETE_MODEPAIEMENT, onDeleteModePaiement) 
}

export default modePaiementsSaga
