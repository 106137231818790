import { APIClient } from "./api_back_conf";
import * as url from "./url_api_back";

const api = new APIClient();
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postUserRegister = (data) => {
  return api.create(url.POST_USER_REGISTER, data)
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postUserLogin = data => api.create(url.POST_USER_LOGIN, data);

// postForgetPwd
const postUserForgetPwd = data => api.create(url.POST_USER_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);

const postUserProfile = data => api.create(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url,data) => {
    return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => api.create(url.POST_USER_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => api.create(url.POST_USER_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);




// USERS METHODES

// get users

export const getUsers = () => api.get(url.GET_USERS);
//console.log("getUsers",api.get(url.GET_USERS))

// get user
export const getUser = (user) => api.get(url.GET_USER,{ headers: { user } });

// add user
export const addNewUser = user => api.create(url.ADD_USER, user);

// update user
export const updateUser = user => api.update(url.UPDATE_USER+user.id,user.data);

// delete user
export const deleteUser = user =>
api.delete(url.DELETE_USER+user, { headers: { user } });




// CATEGORIES

// get categories
export const getCategories = () =>api.get(url.GET_CATEGORIES);
//console.log("getCat",api.get(url.GET_CATEGORIES))
// get   categorie
export const getCategorie = (cat) => api.get(url.GET_CATEGORIE+cat,{ headers: { cat } });

// add categorie
export const addNewCategorie = data => api.create(url.ADD_CATEGORIE, data);


// update categorie
export const updateCategorie = (cat) => api.update(url.UPDATE_CATEGORIE+cat.id,cat.data);

// delete categorie
export const deleteCategorie = (cat) =>
api.delete(url.DELETE_CATEGORIE+cat, { headers: { cat } });





// PRODUITS

// get produit
export const getProduits = () =>api.get(url.GET_PRODUITS); 
// get   produit
export const getProduit = (prod) => api.get(url.GET_PRODUIT+prod,{ headers: { prod } });

// add produit
export const addNewProduit = data => api.create(url.ADD_PRODUIT, data);


// update produit
export const updateProduit = (prod) => { api.update(url.UPDATE_PRODUIT+prod.id,prod.data)};

// delete produit
export const deleteProduit = (prod) =>
api.delete(url.DELETE_PRODUIT+prod, { headers: { prod } });



// PouvementStock

// get MouvementStock
export const getMouvementStocks = () =>api.get(url.GET_MOUVEMENTSTOCKS); 
// get   MouvementStock
export const getMouvementStock = (m) => api.get(url.GET_MOUVEMENTSTOCK+m,{ headers: { m } });

// add MouvementStock

export const addNewMouvementStock = data => {api.create(url.ADD_MOUVEMENTSTOCK, data)};

// update MouvementStock
export const updateMouvementStock = (m) => { api.update(url.UPDATE_MOUVEMENTSTOCK+m.id,m.data)};

// delete MouvementStock
export const deleteMouvementStock = (m) =>
api.delete(url.DELETE_MOUVEMENTSTOCK+m, { headers: { m } });




// AGENTS

// get agents
export const getAgents = () =>api.get(url.GET_AGENTS); 
// get   agent
export const getAgent = (ag) => api.get(url.GET_AGENT+ag,{ headers: { ag } });

// add Agent
export const addNewAgent = data => api.create(url.ADD_AGENT, data);


// update Agent
export const updateAgent = (ag) => { api.update(url.UPDATE_AGENT+ag.id,ag.data)};

// delete Agent
export const deleteAgent = (ag) =>
api.delete(url.DELETE_AGENT+ag, { headers: { ag } });


// EQUIPES

// get equipes
export const getEquipes = () =>api.get(url.GET_EQUIPES);
//console.log("getCat",api.get(url.GET_EQUIPES))
// get   equipe
export const getEquipe = (eq) => api.get(url.GET_EQUIPE+eq,{ headers: { eq } });

// add equipe
export const addNewEquipe = data => api.create(url.ADD_EQUIPE, data);


// update equipe
export const updateEquipe = (eq) => api.update(url.UPDATE_EQUIPE+eq.id,eq.data);

// delete equipe
export const deleteEquipe = (eq) =>
api.delete(url.DELETE_EQUIPE+eq, { headers: { eq } });








// CUVES

// get Cuve
export const getCuves = () =>api.get(url.GET_CUVES); 
// get   Cuve
export const getCuve = (cuv) => api.get(url.GET_CUVE+cuv,{ headers: { cuv } });

// add Cuve
export const addNewCuve = data => api.create(url.ADD_CUVE, data);


// update Cuve
export const updateCuve = (cuv) => {console.log('cuve',cuv); api.update(url.UPDATE_CUVE+cuv.id,cuv.data)};

// delete Cuve
export const deleteCuve = (cuv) =>
api.delete(url.DELETE_CUVE+cuv, { headers: { cuv } });



// FOURNISSEURS

// get Fournisseur
export const getFournisseurs = () =>api.get(url.GET_FOURNISSEURS); 
// get   Fournisseur
export const getFournisseur = (Frs) => api.get(url.GET_FOURNISSEUR+Frs,{ headers: { Frs } });

// add Fournisseur
export const addNewFournisseur = data => api.create(url.ADD_FOURNISSEUR, data);


// update Fournisseur
export const updateFournisseur = (Frs) => {  api.update(url.UPDATE_FOURNISSEUR+Frs.id,Frs.data)};

// delete Fournisseur
export const deleteFournisseur = (Frs) =>
api.delete(url.DELETE_FOURNISSEUR+Frs, { headers: { Frs } });




// POMPES

// get Pompe
export const getPompes = () =>api.get(url.GET_POMPES); 

// get   Pompe
export const getPompe = (pomp) => api.get(url.GET_POMPE+pomp,{ headers: { pomp } });

// add Pompe
export const addNewPompe = (pomp) => api.create(url.ADD_POMPE, pomp);


// update Pompe
export const updatePompe = (pomp) => { api.update(url.UPDATE_POMPE+pomp.id,pomp.data)};

// delete Pompe
export const deletePompe = (pomp) =>
api.delete(url.DELETE_POMPE+pomp, { headers: { pomp } });




// PRIX

// get Prix
export const getPrixx = () =>api.get(url.GET_ALL_PRIX); 
// get   Prix
export const getPrix = (px) => api.get(url.GET_PRIX+px,{ headers: { px } });

// add Prix
export const addNewPrix = data => api.create(url.ADD_PRIX, data);


// update Prix
export const updatePrix = (px) => { api.update(url.UPDATE_PRIX+px.id,px.data)};

// delete Prix
export const deletePrix = (px) =>
api.delete(url.DELETE_PRIX+px, { headers: { px } });



// STOCKS

// get Stock
export const getStocks = () =>api.get(url.GET_STOCKS); 
// get   Stock
export const getStock = (stck) => api.get(url.GET_STOCK+stck,{ headers: { stck } });

// add Stock
export const addNewStock = data => api.create(url.ADD_STOCK, data);


// update Stock
export const updateStock = (stck) => { api.update(url.UPDATE_STOCK+stck.id,stck.data)};

// delete Stock
export const deleteStock = (stck) =>
api.delete(url.DELETE_STOCK+stck, { headers: { stck } });

 
//APPROVISIONNEMENT 
// get Appro
export const getApprovisionnements = () =>api.get(url.GET_APPROS); 
// get   Appro
export const getApprovisionnement = (appr) => api.get(url.GET_APPRO+appr,{ headers: { appr } });

// add Appro
export const addNewApprovisionnement = data => api.create(url.ADD_APPRO, data);


// update Appro
export const updateApprovisionnement = (appr) => { api.update(url.UPDATE_APPRO+appr.id,appr.data)};

// delete Appro
export const deleteApprovisionnement = (appr) =>
api.delete(url.DELETE_APPRO+appr, { headers: { appr } });


//SALAIRE 
// get Salaire
export const getSalaires = () =>api.get(url.GET_SALAIRES); 
// get   Salaire
export const getSalaire = (salaire) => api.get(url.GET_SALAIRE+salaire,{ headers: { salaire } });

// add Salaire
export const addNewSalaire = data => api.create(url.ADD_SALAIRE, data);


// update Salaire
export const updateSalaire = (salaire) => { api.update(url.UPDATE_SALAIRE+salaire.id,salaire.data)};

// delete Salaire
export const deleteSalaire = (salaire) =>
api.delete(url.DELETE_SALAIRE+salaire, { headers: { salaire } });


// DEPENSE

// get Depense
export const getDepenses = () =>api.get(url.GET_DEPENSES); 
// get   Depense
export const getDepense = (dep) => api.get(url.GET_DEPENSE+dep,{ headers: { dep } });

// add Depense
export const addNewDepense = data => api.create(url.ADD_DEPENSE, data);


// update Depense
export const updateDepense = (dep) => { api.update(url.UPDATE_DEPENSE+dep.id,dep.data)};

// delete Depense
export const deleteDepense = (dep) =>
api.delete(url.DELETE_DEPENSE+dep, { headers: { dep } });


// Demandes

// get demande
export const getDemandes = () =>api.get(url.GET_DEMANDES); 
// get   demandes
export const getDemande = (dmd) => api.get(url.GET_DEMANDE+dmd,{ headers: { dmd } });

// add demadnde
export const addNewDemande = data => api.create(url.ADD_DEMANDE, data);


// update demande
export const updateDemande = (dmd) => { api.update(url.UPDATE_DEMANDE+dmd.id,dmd.data)};

// delete dmeande
export const deleteDemande = (dmd) =>
api.delete(url.DELETE_DEMANDE+dmd, { headers: { dmd } });


// mode remboursement 

// get demande
export const getModeRemboursements = () =>api.get(url.GET_MODEREMBOURSEMENTS); 
// get   demandes
export const getModeRemboursement = (rd) => api.get(url.GET_MODEREMBOURSEMENT+rd,{ headers: { rd } });

// add demadnde
export const addNewModeRemboursement = rd => api.create(url.ADD_MODEREMBOURSEMENT, rd);


// update demande
export const updateModeRemboursement = (rd) => { api.update(url.UPDATE_MODEREMBOURSEMENT+rd.id,rd.data)};

// delete dmeande
export const deleteModeRemboursement = (rd) =>
api.delete(url.DELETE_MODEREMBOURSEMENT+rd, { headers: { rd } });


// type Demandes

// get demande
export const getTypeDemandes = () =>api.get(url.GET_TYPEDEMANDES); 
// get   demandes
export const getTypeDemande = (dmd) => api.get(url.GET_TYPEDEMANDE+dmd,{ headers: { dmd } });

// add demadnde
export const addNewTypeDemande = data => api.create(url.ADD_TYPEDEMANDE, data);


// update demande
export const updateTypeDemande = (dmd) => { api.update(url.UPDATE_TYPEDEMANDE+dmd.id,dmd.data)};

// delete dmeande
export const deleteTypeDemande = (dmd) =>
api.delete(url.DELETE_TYPEDEMANDE+dmd, { headers: { dmd } });


// DepenseCategorie

// get DepenseCategorie
export const getDepenseCategories = () =>api.get(url.GET_DEPENSE_CATEGORIES); 
// get   DepenseCategorie
export const getDepenseCategorie = (dep) => api.get(url.GET_DEPENSE_CATEGORIE+dep,{ headers: { dep } });

// add DepenseCategorie
export const addNewDepenseCategorie = data => api.create(url.ADD_DEPENSE_CATEGORIE, data);


// update DepenseCategorie
export const updateDepenseCategorie = (dep) => { api.update(url.UPDATE_DEPENSE_CATEGORIE+dep.id,dep.data)};

// delete DepenseCategorie
export const deleteDepenseCategorie = (dep) =>
api.delete(url.DELETE_DEPENSE_CATEGORIE+dep, { headers: { dep } });




// DepenseSousCategorie

// get DepenseSousCategorie
export const getDepenseSousCategories = () =>api.get(url.GET_DEPENSE_SOUSCATEGORIES); 
// get   DepenseCategorie
export const getDepenseSousCategorie = (dep) => api.get(url.GET_DEPENSE_SOUSCATEGORIE+dep,{ headers: { dep } });

// add DepenseCategorie
export const addNewDepenseSousCategorie = data => api.create(url.ADD_DEPENSE_SOUSCATEGORIE, data);


// update DepenseCategorie
export const updateDepenseSousCategorie = (dep) => { api.update(url.UPDATE_DEPENSE_SOUSCATEGORIE+dep.id,dep.data)};

// delete DepenseCategorie
export const deleteDepenseSousCategorie = (dep) =>
api.delete(url.DELETE_DEPENSE_SOUSCATEGORIE+dep, { headers: { dep } });





// DetailCuve

// get DetailCuve
export const getDetailCuves = () =>api.get(url.GET_DETAIL_CUVES); 
// get   DetailCuve
export const getDetailCuve = (dCuv) => api.get(url.GET_DETAIL_CUVE+dCuv,{ headers: { dCuv } });

// add DetailCuve
export const addNewDetailCuve = data => api.create(url.ADD_DETAIL_CUVE, data);


// update DetailCuve
export const updateDetailCuve = (dCuv) => { api.update(url.UPDATE_DETAIL_CUVE+dCuv.id,dCuv.data)};

// delete DetailCuve
export const deleteDetailCuve = (dCuv) =>
api.delete(url.DELETE_DETAIL_CUVE+dCuv, { headers: { dCuv } });



// get vente
export const getVentes = () =>api.get(url.GET_VENTES); 
// get   vente
export const getVente = (vt) => api.get(url.GET_VENTE+vt,{ headers: { vt } });

// add vente
export const addNewVente = data => api.create(url.ADD_VENTE, data);


// update vente
export const updateVente = (vt) => { api.update(url.UPDATE_VENTE+vt.id,vt.data)};

// delete vente
export const deleteVente = (vt) =>
api.delete(url.DELETE_VENTE+vt, { headers: { vt } });


// get client
export const getClients = () =>api.get(url.GET_CLIENTS); 
// get   client
export const getClient = (vt) => api.get(url.GET_CLIENT+vt,{ headers: { vt } });

// add client
export const addNewClient = data => api.create(url.ADD_CLIENT, data);


// update client
export const updateClient = (vt) => { api.update(url.UPDATE_CLIENT+vt.id,vt.data)};

// delete client
export const deleteClient = (vt) =>
api.delete(url.DELETE_CLIENT+vt, { headers: { vt } });


// get facture
export const getFactures = () =>api.get(url.GET_FACTURES); 
// get   facture
export const getFacture = (vt) => api.get(url.GET_FACTURE+vt,{ headers: { vt } });

// add facture
export const addNewFacture = data => api.create(url.ADD_FACTURE, data);


// update facture
export const updateFacture = (vt) => { api.update(url.UPDATE_FACTURE+vt.id,vt.data)};

// delete facture
export const deleteFacture = (vt) =>
api.delete(url.DELETE_FACTURE+vt, { headers: { vt } });


// get factureDetails
export const getFactureDetailss = () =>api.get(url.GET_FACTUREDETAILS); 
// get   factureDetails
export const getFactureDetails = (vt) => api.get(url.GET_FACTUREDETAILS+vt,{ headers: { vt } });

// add factureDetails
export const addNewFactureDetails = data => api.create(url.ADD_FACTUREDETAILS, data);


// update factureDetails
export const updateFactureDetails = (vt) => { api.update(url.UPDATE_FACTUREDETAILS+vt.id,vt.data)};

// delete factureDetails
export const deleteFactureDetails = (vt) =>
api.delete(url.DELETE_FACTUREDETAILS+vt, { headers: { vt } });


// get paiement
export const getPaiements = () =>api.get(url.GET_PAIEMENT); 
// get   paiement
export const getPaiement = (vt) => api.get(url.GET_PAIEMENT+vt,{ headers: { vt } });

// add paiement
export const addNewPaiement = data => api.create(url.ADD_PAIEMENT, data);


// update paiement
export const updatePaiement = (vt) => { api.update(url.UPDATE_PAIEMENT+vt.id,vt.data)};

// delete paiement
export const deletePaiement = (vt) =>
api.delete(url.DELETE_PAIEMENT+vt, { headers: { vt } });


// get modePaiement
export const getModePaiements = () =>api.get(url.GET_MODEPAIEMENT); 
// get   modePaiement
export const getModePaiement = (vt) => api.get(url.GET_MODEPAIEMENT+vt,{ headers: { vt } });

// add modePaiement
export const addNewModePaiement = data => api.create(url.ADD_MODEPAIEMENT, data);


// update modePaiement
export const updateModePaiement = (vt) => { api.update(url.UPDATE_MODEPAIEMENT+vt.id,vt.data)};

// delete modePaiement
export const deleteModePaiement = (vt) =>
api.delete(url.DELETE_MODEPAIEMENT+vt, { headers: { vt } });



//files managements 

// get files
export const getFiles = () =>api.get(url.GET_FILES); 
// get   file
export const getFile = (f) => api.get(url.GET_FILE+f,{ headers: { f } });

// add file
export const addNewFile = data => api.create(url.ADD_FILE, data);


// update file
export const updateFile = (f) => { api.update(url.UPDATE_FILE+f.id,f.data)};

// delete file
export const deleteFile = (f) =>
api.delete(url.DELETE_FILE+f, { headers: { f } });



export {
  getLoggedInUser,
  isUserAuthenticated,
  postUserRegister,
  postUserLogin,
  postUserProfile,
  postUserForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
 

};
