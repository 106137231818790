import { all, fork } from "redux-saga/effects";

import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";

import usersSaga from "./users/saga";


import categoriesSaga from "./categories/saga";
import produitsSaga from "./produits/saga";

import mouvementStocksSaga from "./mouvementStocks/saga";

import equipesSaga from "./equipes/saga";
// importattion des elements de Dembele 23/04/2023
//agents saga
import agentsSaga from "./agents/saga";

//approvisionnement saga
import approvisionnementsSaga from "./approvisionnements/saga";


//salaires saga
//import salairesSaga from "./salaires/saga";

//cuves saga
import cuvesSaga from "./cuves/saga";

//detailCuves saga
import detailCuvesSaga from "./detailCuves/saga";


//depenses saga
import depensesSaga from "./depenses/saga";

//depenses saga
import demandesSaga from "./demandes/saga";

//depenses saga
import typeDemandesSaga from "./typeDemandes/saga";


//depenses saga
import modeRemboursementsSaga from "./modeRemboursements/saga";

//salaires saga
import salairesSaga from "./salaires/saga";

//depenseCategories saga
import depenseCategoriesSaga from "./depenseCategories/saga";
import depenseSousCategoriesSaga from "./depenseSousCategories/saga";

//fournisseurs saga
import fournisseursSaga from "./fournisseurs/saga";

//pompes saga
import pompesSaga from "./pompes/saga";

//prix saga
import prixSaga from "./prix/saga";

//stocks saga
import stocksSaga from "./stocks/saga";

//ventes saga
import ventesSaga from "./ventes/saga";

//files saga
import filesSaga from "./files/saga";


//clients saga
import clientsSaga from "./facturations/clients/saga";
//factures saga
import facturesSaga from "./facturations/factures/saga";
//factureDetails saga
import factureDetailsSaga from "./facturations/factureDetails/saga";
//paiements saga
import paiementsSaga from "./facturations/paiements/saga";
//modePaiements saga
import modePaiementsSaga from "./facturations/modePaiements/saga";
  


import accountSaga from "./auth/register/saga";
import ProfileSaga from "./auth/profile/saga";
import authSaga from "./auth/login/saga";
import forgetPasswordSaga from "./auth/forgetpwd/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(usersSaga),
    fork(categoriesSaga),
    fork(equipesSaga),

    fork(produitsSaga), 
    fork(mouvementStocksSaga),

    fork(agentsSaga), 
    fork(approvisionnementsSaga), 
    fork(salairesSaga), 
    fork(cuvesSaga), 
    fork(detailCuvesSaga), 
    fork(depensesSaga),
    fork(demandesSaga),
    fork(typeDemandesSaga),
    fork(modeRemboursementsSaga),
     fork(depenseCategoriesSaga), 
     fork(depenseSousCategoriesSaga), 
    fork(fournisseursSaga), 
    fork(pompesSaga), 
    fork(prixSaga), 
    fork(stocksSaga), 
    fork(ventesSaga),  
    fork(filesSaga),  
    fork(clientsSaga), 
    fork(facturesSaga), 
    fork(factureDetailsSaga), 
    fork(paiementsSaga), 
    fork(modePaiementsSaga), 

    fork(accountSaga),
    fork(ProfileSaga),
    fork(authSaga),
    fork(forgetPasswordSaga)
  ]);

  

}
