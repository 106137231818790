import {
  GET_PAIEMENTS_SUCCESS,
  GET_PAIEMENTS_FAIL,
  GET_PAIEMENT_SUCCESS,
  GET_PAIEMENT_FAIL,
  ADD_PAIEMENT_SUCCESS,
  ADD_PAIEMENT_FAIL,
  UPDATE_PAIEMENT_SUCCESS,
  UPDATE_PAIEMENT_FAIL,
  DELETE_PAIEMENT_SUCCESS,
  DELETE_PAIEMENT_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  paiements: [],
  error: {},
};

const Paiements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAIEMENTS_SUCCESS:
      return {
        ...state,
        paiements: action.payload,
      };

    case GET_PAIEMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PAIEMENT_SUCCESS:
      return {
        ...state,
        paiement: action.payload,
      };
  
    case GET_PAIEMENT_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_PAIEMENT_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        paiements: [...state.paiements, action.payload],
      };

    case ADD_PAIEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PAIEMENT_SUCCESS:
      return {
        ...state,
        paiements: state.paiements.map(paiement =>
          paiement.id.toString() === action.payload.id.toString()
            ? { paiement, ...action.payload }
            : paiement
        ),
        isPaiementUpdated: true
      };

    case UPDATE_PAIEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isPaiementUpdated: false
      };

    case DELETE_PAIEMENT_SUCCESS:
      return {
        ...state,
        paiements: state.paiements.filter(
          paiement => paiement.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PAIEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Paiements;
