import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import Select  from 'react-select';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import { FaBold, FaEdit, FaTrash } from 'react-icons/fa';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

 
import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';


 
import PDFGenerator from '../../utilitaires/PDFGenerator';
import  {moisAnnee} from '../../utilitaires/moisAnnee';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,
    getAgents as onGetAgents,
 
 


    //salaires
    getDemandes as onGetDemandes,
    addNewSalaire as onAddNewSalaire,
    deleteSalaire as onDeleteSalaire,
    getSalaires as onGetSalaires,
    getSalaire as onGetSalaire,
    updateSalaire as onUpdateSalaire,
  } from "../../store/actions"; 

  
 
const Salaires = () => {
const salaires = useSelector((state) => ({  salaires: state.salaires.salaires  }));
/* const categories = useSelector((state) => ({  categories:  state.categories.categories  }));
const fournisseurs = useSelector((state) => ({  fournisseurs:  state.fournisseurs.fournisseurs  })); */
const agents = useSelector((state) => ({  agents:  state.agents.agents  }));
const demandes = useSelector((state) => ({  demandes:  state.demandes.demandes  }));
const users = useSelector((state) => ({  users:  state.users.users  }));


    // Declaration du fournisseur et des produits choisi
const today = new Date(); 
const todayEnregDate = new Date().toISOString().split('T')[0];
const [paie, setPaie] = useState('');
const [validateButtonDisabled, setValidateButtonDisabled] = useState(true);
const [frs, setFrs] = useState('');
const [numPaie,setNumPaie]=useState(null);
/* const [agent,setAgent]=useState(); */
const [matricule,setMatricule]=useState(null);
const [salaireBase,setSalaireBase]=useState(0);
const [montantPaye,setMontantPaye]=useState(0);
const [pret,setPret]=useState(0);
const [mois,setMois]=useState(null); 
const [periode,setPeriode] = useState(todayEnregDate) 
const [agent, setAgent] = useState('');

const [selectedAgnts, setSelectedAgnts] = useState([]);  
const [paies, setPaies] = useState([]);

const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();


const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };

 
const startNewPaie =()=>{
    //setNumPaie('')
    setNumPaie(today.getTime())
    setPaies([])

  }
   
/// option  pour afficher la liste des produits et la liste des fournisseurs
/* const frsOptions=Object.values(fournisseurs.fournisseurs).map(frs => ({
    value:frs.id, label:frs.libelle
})) */


    //liste des id des agents deja selectionner 
    const paiesAgentIds = paies? paies.map(paie => paie.agent):'';
    console.log('demandes',demandes)

   
const agentsOptFiltered=Object.values(agents.agents).filter( agent =>agent.actif==1 && !paiesAgentIds.includes(agent.id));

//console.log('agentsOptFiltered',agentsOptFiltered)
const agentOptions=agentsOptFiltered.map(ag => ({
    value:ag.id, label:ag.prenom+' '+ag.nom
}))
 
const moisOptions =moisAnnee().map(m => ({
    value:m.id, label:m.name
}));
console.log('mois',mois)
/* const handleChangeMois =(mois)=>{
  
    setFrs(frs.value)

    } */



// fonction pour ajouter un nouveau agent
let updatedAgents = []; 

/* for (const a of selectedAgnts){
    const agent = Object.values(agents.agents).find(ag => ag.id === a); 
 
    const newAgent = {
    // id: paies.length + 1,
      numPaie: numPaie,
      agent: a,
      matricule:agent.matricule,
      salaireBase:agent.salaireBase,
      pret:agent.pret,
      montantPaye:agent.montantPaye,
      mois:mois, 
      periode:periode,
     
    };
    console.log('newAgent',newAgent) 
    
}*/
//setPaies([...paies, updatedAgents]);
//setPaies( updatedAgents);




 
/* const handleAddAgent = (ag) => {
    const agent = Object.values(agents.agents).find(a => a.id === ag); 
 
    const newAgent = {
    // id: paies.length + 1,
      numPaie: numPaie,
      agent: ag,
      matricule:agent.matricule,
      salaireBase:agent.salaireBase,
      pret:agent.pret,
      montantPaye:agent.montantPaye,
      mois:mois, 
      periode:periode,
     
    };
    console.log('newAgent add',newAgent)
    setPaies([...paies, newAgent]);
};   */
//console.log('paies',paies)
/* for (var i = 0; i < selectedAgnts.length; i++) {
    //handleAddAgent(selectedAgnts[i])
   console.log('selectedAgnts[i]',selectedAgnts[i])
} */

 
/// lorsque l'utilisateur choisi un agent
// Créez une copie du tableau existant
const handleChangeAgent =(a)=>{
    console.log("a",a)  
    if(a.selectedOption[0]){
        const aId= a.selectedOption
        console.log("aId[] ",aId)  
        for(const i=0 ;i<aId.length ;i++){
            const agent = Object.values(agents.agents).find(ag => ag.id==aId[i] );
            const dmdPret =Object.values(demandes.demandes).find(d => d.agent==agent.id && d.modeRemboursement==1 );
            console.log('dmdPret',dmdPret)
            const newAgent = {
                // id: paies.length + 1,
                  numPaie: numPaie,
                  agent: aId[i],
                  matricule:agent.matricule,
                  salaireBase:agent.salaireBase,
                  pret:dmdPret?(dmdPret.montant-dmdPret.montantPaye):0,
                  montantPaye:agent.salaireBase-(dmdPret?(dmdPret.montant-dmdPret.montantPaye):0),
                  mois:mois, 
                  periode:periode,
                 
                }; 
                setPaies([...paies, newAgent]) 
        } 
    }
    if(a.selectedOption.value){
        const aId= a.selectedOption.value
        console.log("aId ",aId)   
        const dmdPret =Object.values(demandes.demandes).find(d => d.agent==aId && d.modeRemboursement==1 );
            const agent = Object.values(agents.agents).find(ag => ag.id==aId );
             
            const newAgent = {
                // id: paies.length + 1,
                  numPaie: numPaie,
                  agent: aId,
                  matricule:agent.matricule,
                  salaireBase:agent.salaireBase,
                  pret:dmdPret?(dmdPret.montant-dmdPret.montantPaye):0,
                  montantPaye:agent.salaireBase-(dmdPret?(dmdPret.montant-dmdPret.montantPaye):0),
                  mois:mois, 
                  periode:periode,
                 
                }; 
                setPaies([...paies, newAgent]) 
        
    }
      
}



console.log('paies',paies)

 

 
    //impression d ecran
    const handlePrint = () => {
        const content = document.getElementById('content');
    
        html2canvas(content).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('landscape');
  
          const contentWidth = canvas.width;
        const contentHeight = canvas.height;
  
        // Calculer les proportions pour adapter l'image au PDF
        const ratio = contentWidth / contentHeight;
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdfWidth / ratio;
  
  
          pdf.addImage(imgData, 'PNG', 0, 0,pdfWidth, pdfHeight);
          pdf.save('capture.png');
        });
      };
 
// Les alerts d'ajout ,modification et suppression
function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 6000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'un paiement
const [produit, setProduit] = useState('');
const [quantite, setQuantite] = useState('');
const [prixAchat, setPrixAchat] = useState('');
const [prixVente, setPrixVente] = useState('');
const [montant, setMontant] = useState('');
const [fournisseur, setFournisseur] = useState('');

const [idPaie, setIdPaie] = useState('');

/* const newPaie = {
    numPaie: numPaie,
    agent:agent,
    matricule:matricule,
    salaireBase:salaireBase,
    montantPaye:montantPaye,
    mois:mois,
    periode:periode, 
}; */
 

const saveNewPaie = (e) => {
    e.preventDefault(); 
    dispatch(onAddNewSalaire(paies));
    setTypMsg('add')
    setmodal_list(false);
    
 
  };

  // Mise a jour d"un paiement 
  const upPaie = { 
    matricule: matricule,
    agent:agent,
    salaireBase:salaireBase,
    montantPaye:montantPaye,
    mois:mois,
    periode:periode,
     
  };

  const saveUpdatePaie = (e) => {
    e.preventDefault(); 
    // console.log("upPaie",upPaie)
    // console.log("idpaieo",idpaieo)
    dispatch(onUpdateSalaire(idPaie,upPaie));
    setTypMsg('upd')
    setmodal_update(false);
    
  };


  /// Suppression d'un paiement
 

  const [listIdPaie, setListIdPaie] = useState([]);

  const idpaieoChecBoxSelected = (paieId) => {
    setListIdPaie(prevSelectedRows => ({
      ...prevSelectedRows,
      [paieId]: !prevSelectedRows[paieId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdPaie)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const paieIds = Object.keys(filteredList);
 
  const deletePaie = ( paie) => { 
    if(paie)dispatch(onDeleteSalaire(paie));
    setTypMsg('del')
    if(listIdPaie && paieIds){paieIds.forEach((paieId) =>dispatch(onDeleteSalaire(paieId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState("");
console.log('filteredData',filteredData)
const dataTable= paies ? 
paies.map(paie => {
    //const frs = Object.values(fournisseurs.fournisseurs).find(fr => fr.id === paie.fournisseur);
    const agnts = Object.values(agents.agents).find(ag=> ag.id === paie.agent);
    const user = Object.values(users.users).find(us => us.id === paie.createdBy);
    return { ...paie , user: user?user.prenom+' '+user.nom :'' ,agentInfo:agnts?agnts.prenom+' '+agnts.nom:'',/* fournisseurInfo:frs?frs.libelle:'' */};
  }) 
  : 
  paies ? paies.map(paie => {
   // const frs = Object.values(fournisseurs.fournisseurs).find(fr => fr.id === paie.fournisseur);
    const agnts = Object.values(agents.agents).find(ag=> ag.id === ag.agent);
    const user = Object.values(users.users).find(us => us.id === paie.createdBy);
    return { ...paie, user: user?user.prenom+' '+user.nom :'' ,agentInfo:agnts?agnts.prenom+' '+agnts.nom:''/* fournisseurInfo:frs?frs.libelle:'' */};//user: user.prenom+' '+user.nom 
  }):'';
//,categoriepaieo:categorie.libelle

 





const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (paie) => {
    const value = paie.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.agent), _.toLower(value))|| 
      _.startsWith(_.toLower(item.matricule), _.toLower(value))||
      _.startsWith(_.toLower(item.salaireBase), _.toLower(value))||
      _.startsWith(_.toLower(item.montantPaye), _.toLower(value))||
      _.startsWith(_.toLower(item.mois), _.toLower(value))||
      _.startsWith(_.toLower(item.periode), _.toLower(value))|| 
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (paie, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


// lorsque l'utilisateur decide de modifier ou de supprimer un element de la table 
const handleEdit = (id, property, value) => {
    const index = paies.findIndex((paie) => paie.numPaie+paie.agent === id);

    const paie = paies[index];
    const salaireBase = property==='salaireBase' ?  value : paie.salaireBase; // récupère la valeur actuelle du salaire de base
    const pret = property==='pret' ?  value : paie.pret; // récupère la valeur actuelle des prets  
    const montantPaye = property==='montantPaye' ?  value : paie.montantPaye; // récupère la valeur actuelle de montantPaye  
    const updatedPaie = { ...paies[index], [property]: value, montantPaye: salaireBase-pret  };
    const updatedPaies = [
      ...paies.slice(0, index),
      updatedPaie,
      ...paies.slice(index + 1)
    ];
    setPaies(updatedPaies);  
    if(validateButtonDisabled===true && paies  ) { setValidateButtonDisabled(false)}
  };

  const handleDelete = (id) => { 
    const updatedPaies = paies.filter((paie) => paie.agent !== id);
    setPaies(updatedPaies);
    if(updatedPaies.length===0) { setValidateButtonDisabled(true)}
  };

  //interdiction des valeurs non numerique
  const handleKeyDown = (e) => {
 
    const { key } = e;

   // console.log('key',key)

  // Interdire les caractères non numériques, sauf la touche Backspace
  // if (!/\d/.test(key)  && (key !== 'Backspace' || key !== '' || key !== 'Delete' || key.key !== '.'))
  if (!/\d/.test(key)  && key !== 'Backspace' ){
  
      if(key !== '.' ) {

        if(key !== 'Tab') {
          if(key !== 'ArrowRight'){
            if(key !== 'ArrowLeft') e.preventDefault() ;
          }
        }   
      } 

 }
};


  ///maping des donnees pour pdf 
  const headText ='LISTE DES PAIES N° '+ paie 
  const tableHead =[['N°','N° Paie', 'Agent', 'Matricule','Salaire Base', 'Pret','Salaire Versé','Mois','Période']]
  const tableD=dataTable?  dataTable.map((ag)=>{ 
  /*   const frs = Object.values(fournisseurs.fournisseurs).find(fr => fr.id === ap.fournisseur); */
    const agent = Object.values(agents.agents).find(agent=> agent.id === ag.agent);
    return { ...ag,agent: agent? agent.prenom+' '+agent.nom:'' };}):''

    const tableData= tableD ? tableD.map((d, index) => [index+1, d.numPaie,  d.agent,parseFloat(d.salaireBase).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.pret).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.montantPaye).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),]):'';

    console.log('dataTable curren',dataTable)
    const currentPageData = dataTable ? dataTable.slice(offset, offset + PER_PAGE).map((paie,index) =>  (
  
    <tr key={paie.numPaie+paie.agent} style={{backgroundColor:"#367E7F",color:"#FFFF",}}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idpaieoChecBoxSelected(paie.numPaie)}}   />
        </div>
    </th>
    <td className="paie_id" style={{ display: "none" }}>{paie.numPaie}</td>
    <td className="paie_index">{index+1}</td> 
    <td className="paie_name">{paie.agentInfo}</td>
    {/* <td  className="paie_name">{paie.quantite}</td> */}
    <td className="paie_name">{paie.matricule}</td>

    <td> <Input type="text"   style= {{backgroundColor:"#8C160D",color:"#FFFF"}}
             value={paie.salaireBase} //.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(paie.numPaie+paie.agent, 'salaireBase',  (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>
    {/* <td className="paie_name">{paie.prixAchat}</td> */}
    <td> <Input type="decimal"   style= {{color:"black"}}
             value={paie.pret}
            onChange={(e) => handleEdit(paie.numPaie+paie.agent, 'pret', e.target.value)}
              />
    </td>
    <td> <Input type="decimal"  style= {{color:"black"}}
             value={paie.montantPaye}
            onChange={(e) => handleEdit(paie.numPaie+paie.agent, 'montantPaye', e.target.value)}
              />
    </td>
    <td className="paie_name">{paie.mois}</td>
    <td className="paie_name">{paie.periode}</td>
    
    <td>
        <div className="d-flex gap-2">
            {/* <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setProduit(paie.agent);setFournisseur(paie.fournisseur);setQuantite(paie.quantite);setPrixAchat(paie.prixAchat);setMontant(paie.montant);setIdpaieo(paie.numpaieo);tog_update()} }>Modifier</button>
            </div> */}
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> handleDelete(paie.agent)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


)):'';

   useEffect(() => { 
       dispatch(onGetAgents()); 
        dispatch(onGetSalaires());
        dispatch(onGetUsers()); 
        dispatch(onGetDemandes());
        
        setFilteredData(paies)
        
    },[dispatch,paies,paie,typMsg,onAddNewSalaire,onUpdateSalaire,onDeleteSalaire]); //onAddNewSalaire,onDeleteSalaire,onUpdateSalaire,paiements

   
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="paiement" breadcrumbItem="Liste des paiements" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                {/* <CardHeader>
                                    <h4 className="card-title mb-0">Effectuer un appporvisionnement</h4>
                                  
                                </CardHeader> */}
                                {numPaie  ? 
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="align-items-start">
                                        {numPaie && paies ? <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                 <Button color="success" /* disabled={validateButtonDisabled} */ className="add-btn" onClick={() => {dispatch(onAddNewSalaire(paies));setPaie('');setTypMsg('add');setNumPaie('');setPaies('')}} id="create-btn">
                                                         Valider</Button>
                                                  {/* <Button color="info" size="lg" onClick={handlePrint}   
                                                    >Imp.Ecran</Button>   */}
                                                    {/* <Button color="success"></Button> */}
                                                    <PDFGenerator tableData={tableData} tableHead={tableHead} headText={headText} />
                                                    
                                                    {/* <Button onClick={'genererWord'}>Générer Word</Button> */}
                                                    
                                                </div> 
                                               
                                            </Col> :''} 
                                                    {numPaie && paies ? <Col className="col-sm-auto">
 
                                                        <label style={{ marginRight: '2px' }} className="mr-2">N° PAIEMENT:</label>
                                                        <input
                                                            type="text"
                                                            style={{ color: '#EB7524',   backgroundColor: '#00023D',fontSize:18 ,fontStyle:'bold'}}
                                                            value={numPaie}
                                                            className="form-control search"
                                                            placeholder="Entrez la facture fournisseur..."
                                                            onChange={(e) => {setNumPaie(e.target.value); 
                                                                if(paies){  const updatedPaies = paies.map((paie) => ({ ...paie, numPaie:e.target.value }));
                                                                setPaies(updatedPaies);  }}}
                                                        />
                                                     
                                            </Col>
                                            :''}   

                                            <Col className="col-sm-auto ">
                                                    
                                                    <label style={{ marginRight: '2px' }}className="mr-2">Mois:</label>
                                                    <Select options={moisOptions} onChange={(e)=>{setMois(e.value)}} />
                                                  {/*   <input
                                                        type="number"
                                                        style={{ color: '#EB7524', backgroundColor: '#00023D',fontSize:20 ,fontStyle:'bold'}}
                                                        value={mois}
                                                        className="form-control search"
                                                        placeholder="mois..."
                                                        onChange={(e) => {setMois(e.target.value); 
                                                            if(paies){  const updatedPaies = paies.map((paie) => ({ ...paie, mois:e.target.value }));
                                                            setPaies(updatedPaies);  }}}
                                                    /> */}
                                                
                                            </Col>  
                                           
                                            <Col className="col-sm-auto ">
                                                    
                                                        <label style={{ marginRight: '2px' }}className="mr-2">DATE:</label>
                                                        <input
                                                            type="date"
                                                            style={{ color: '#EB7524', backgroundColor: '#00023D',fontSize:20 ,fontStyle:'bold'}}
                                                            value={periode}
                                                            className="form-control search"
                                                            placeholder="Période de paiement..."
                                                            onChange={(e) => {setPeriode(e.target.value); 
                                                                if(paies){  const updatedPaies = paies.map((paie) => ({ ...paie, periode:e.target.value }));
                                                                setPaies(updatedPaies);  }}}
                                                        />
                                                    
                                                </Col>                                
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                {/* <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div> */}
                                     <span style={{color: '#000C7B', fontSize: '40px',fontFamily:"inherit",fontStyle:"normal",fontWeight:"bold", }}>PAIEMENT SALAIRE</span>

                                            </Col>
                                        </Row>
                                        <Row>
                                          
                                            <Col>
                                            <label htmlFor="frsSelect">Sélectionner un agent</label>
                                           {/*  <Select options={agentOptions}    onChange={handleChangeAgent}/> */}
                                           <MultiSelect value={selectedAgnts} onChange={(e) => {/* setSelectedAgnts(e.value); */handleChangeAgent(e)}}options={agentOptions} optionLabel="label" 
                                                filter placeholder="Choisir un ou des agent(s) " maxSelectedLabels={10} className="w-full md:w-30rem" />
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr >
                                                        <th scope="col" style={{ backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray',width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="paie_id" onClick={(paie) => handleSort(paie, 'id')}>N°</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="fournisseur" onClick={(paie) => handleSort(paie, 'agent')}>Agent</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="produit" onClick={(paie) => handleSort(paie, 'matricule')}>Matricule</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="quantite" onClick={(paie) => handleSort(paie, 'salaireBase')}>Salaire de Base</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="prixAchat" onClick={(paie) => handleSort(paie, 'pret')}>Pret non remboursé</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="prixVente" onClick={(paie) => handleSort(paie, 'montantPaye')}>Reste à Payer</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="montant" onClick={(paie) => handleSort(paie, 'mois')}>Mois</th> 
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="montant" onClick={(paie) => handleSort(paie, 'periode')}>Periode</th>
                                                        {/* <th className="sort" data-sort="createdBy" onClick={(paieo) => handleSort(paieo, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(paieo) => handleSort(paieo, 'createdAt')}>Date Création</th> */}
                                                    
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                {numPaie && paies ? <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody> :'' }
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                         <div style={{textAlign: 'right',fontSize:30,fontFamily:'monospace',fontStyle:'bold'}}> <h1>AGENTS: <span style={{color: '#377E47'}}>{paies.length}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  MONTANT:  <span style={{color: '#0016BF'}}>{parseFloat(paies.reduce((acc, pai) => acc + pai.montantPaye, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span></h1></div>

                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                                : <div><Button color="primary"  onClick={startNewPaie}>NOUVEAU PAIEMENT</Button> <Button color="secondary" tag={Link}  to="/HistoSalaires">HISTORIQUE PAIEMENTS</Button></div>} 
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Paiement </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewPaie}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="agent" className="form-label">Agent</label>
                            <input type="text" id="produit" className="form-control" placeholder="Enter produit" required  onChange={(e) => setProduit(e.target.value)}/>
                            <label htmlFor="quantite" className="form-label">quantite</label>
                            <input type="number" id="quantite" className="form-control" placeholder="Enter quantite" required  onChange={(e) => setQuantite(e.target.value)}/>
                            <label htmlFor="prixAchat" className="form-label">prixAchat</label>
                            <input type="number" id="prixAchat" className="form-control" placeholder="Enter prixAchat" required  onChange={(e) => setPrixAchat(e.target.value)}/>
                            <label htmlFor="motant" className="form-label">quantite</label>
                            <input type="number" id="montant" className="form-control" placeholder="Enter montant" required  onChange={(e) => setMontant(e.target.value)}/>
                            <label htmlFor="fournisseur" className="form-label">Fournisseur</label>
                            <input type="text" id="fournisseur" className="form-control" placeholder="Enter Fournisseur" required  onChange={(e) => setFournisseur(e.target.value)}/>
                            {/* <label htmlFor="categorie" className="form-label">categorie</label>
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setCategoriepaieo(e.target.value)}  defaultValue="0">
                                {Object.values(categories.categories).map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                    {cat.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup> */}

                        
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewPaie}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour paiement </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdatePaie}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="produit" className="form-label">Produit</label>
                            <input type="text" id="produit" className="form-control" placeholder="Enter produit" required  value={produit} onChange={(event) => setProduit(event.target.value)}/>
                            <label htmlFor="quantite" className="form-label">Quantite</label>
                            <input type="number" id="quantite" className="form-control" placeholder="quantite" required   value={quantite}   onChange={(event) => setQuantite(event.target.value)}/>
                            <label htmlFor="prixAchat" className="form-label">prixAchat</label>
                            <input type="number" id="prixAchat" className="form-control" placeholder="prixAchat" required   value={prixAchat}   onChange={(event) => setPrixAchat(event.target.value)}/>
                            <label htmlFor="montant" className="form-label">Montant</label>
                            <input type="number" id="montant" className="form-control" placeholder="montant" required   value={montant}   onChange={(event) => setMontant(event.target.value)}/>
                             
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdatePaie}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Salaires;
