/* FACTURES */
export const GET_FACTURES = "GET_FACTURES";
export const GET_FACTURES_SUCCESS = "GET_FACTURES_SUCCESS";
export const GET_FACTURES_FAIL = "GET_FACTURES_FAIL";

export const GET_FACTURE = "GET_FACTURE";
export const GET_FACTURE_SUCCESS = "GET_FACTURE_SUCCESS";
export const GET_FACTURE_FAIL = "GET_FACTURE_FAIL";

export const ADD_NEW_FACTURE = "ADD_NEW_FACTURE";
export const ADD_FACTURE_SUCCESS = "ADD_FACTURE_SUCCESS";
export const ADD_FACTURE_FAIL = "ADD_FACTURE_FAIL";

export const UPDATE_FACTURE = "UPDATE_FACTURE";
export const UPDATE_FACTURE_SUCCESS = "UPDATE_FACTURE_SUCCESS";
export const UPDATE_FACTURE_FAIL = "UPDATE_FACTURE_FAIL";

export const DELETE_FACTURE = "DELETE_FACTURE";
export const DELETE_FACTURE_SUCCESS = "DELETE_FACTURE_SUCCESS";
export const DELETE_FACTURE_FAIL = "DELETE_FACTURE_FAIL";

 