/* FACTUREDETAILSS */
export const GET_FACTUREDETAILSS = "GET_FACTUREDETAILSS";
export const GET_FACTUREDETAILSS_SUCCESS = "GET_FACTUREDETAILSS_SUCCESS";
export const GET_FACTUREDETAILSS_FAIL = "GET_FACTUREDETAILSS_FAIL";

export const GET_FACTUREDETAILS = "GET_FACTUREDETAILS";
export const GET_FACTUREDETAILS_SUCCESS = "GET_FACTUREDETAILS_SUCCESS";
export const GET_FACTUREDETAILS_FAIL = "GET_FACTUREDETAILS_FAIL";

export const ADD_NEW_FACTUREDETAILS = "ADD_NEW_FACTUREDETAILS";
export const ADD_FACTUREDETAILS_SUCCESS = "ADD_FACTUREDETAILS_SUCCESS";
export const ADD_FACTUREDETAILS_FAIL = "ADD_FACTUREDETAILS_FAIL";

export const UPDATE_FACTUREDETAILS = "UPDATE_FACTUREDETAILS";
export const UPDATE_FACTUREDETAILS_SUCCESS = "UPDATE_FACTUREDETAILS_SUCCESS";
export const UPDATE_FACTUREDETAILS_FAIL = "UPDATE_FACTUREDETAILS_FAIL";

export const DELETE_FACTUREDETAILS = "DELETE_FACTUREDETAILS";
export const DELETE_FACTUREDETAILS_SUCCESS = "DELETE_FACTUREDETAILS_SUCCESS";
export const DELETE_FACTUREDETAILS_FAIL = "DELETE_FACTUREDETAILS_FAIL";

 