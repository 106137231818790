import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Calendar
import calendar from "./calendar/reducer";

// Users
import users from "./users/reducer";


// Categoirie
import categories from "./categories/reducer";

// Equipes
import equipes from "./equipes/reducer";


// Produit
import produits from "./produits/reducer";

// mouvementStock
import mouvementStocks from "./mouvementStocks/reducer";


/** Importation des elements de Demebele 23/04/2023 */
// Agents
import agents from "./agents/reducer";

// approvisionements
import approvisionnements from "./approvisionnements/reducer";

// salaires
import salaires from "./salaires/reducer";

// cuves
import cuves from "./cuves/reducer";

// detail cuves
import detailCuves from "./detailCuves/reducer";

// depenses
import depenses from "./depenses/reducer";// Produit

// demande
import demandes from "./demandes/reducer";// Produit
// demande
import typeDemandes from "./typeDemandes/reducer";// Produit
// demande


import modeRemboursements from "./modeRemboursements/reducer";// Produit
 

// categories depenses
import depenseCategories from "./depenseCategories/reducer";
import depenseSousCategories from "./depenseSousCategories/reducer";

// fournisseurs
import fournisseurs from "./fournisseurs/reducer";

// pompes
import pompes from "./pompes/reducer";

// prix
import prix from "./prix/reducer";

// stock
import stocks from "./stocks/reducer";

// ventes
import ventes from "./ventes/reducer";

// files
import files from "./files/reducer";


// clients
import clients from "./facturations/clients/reducer";
// factures
import factures from "./facturations/factures/reducer";
// facture
import factureDetails from "./facturations/factureDetails/reducer";
// paiements
import paiements from "./facturations/paiements/reducer";
// modePaiements
import modePaiements from "./facturations/modePaiements/reducer";

 




// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import account from "./auth/register/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  calendar,
  users,
  categories,
  produits,
  mouvementStocks,
  agents,
  equipes,
  approvisionnements,
 
  cuves,
  detailCuves,
  depenseCategories,
  depenseSousCategories,
  depenses,
  demandes,
  typeDemandes,
  modeRemboursements,
  salaires,
  fournisseurs,
  pompes,
  files,
  prix,
  stocks,
  ventes,
  forgetPassword,
  login,
  profile,
  account,
  clients,
  factures,
  factureDetails,
  paiements,
  modePaiements,
});


export default rootReducer;
