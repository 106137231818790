import React, { useState, useEffect } from 'react'; 
import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,
    //clients
    getClients as onGetClients,

    //factures
    addNewFacture as onAddNewFacture,
    deleteFacture as onDeleteFacture,
    getFactures as onGetFactures,
    getFacture as onGetFacture,
    updateFacture as onUpdateFacture,
  } from "../../../store/actions"; 

  
 
const Factures = () => {
const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();
 //console.log("Document",Document)
   
const clients = useSelector((state) => ({  clients: state.clients.clients  }));
const factures = useSelector((state) => ({  factures:  state.factures.factures  }));
const users = useSelector((state) => ({  users:  state.users.users  }));


const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };




function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 12000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'une facture
const [libelle, setLibelle] = useState('');
const [client, setClient] = useState('');
const [description, setDescription] = useState('');
const [dateFacture, setDateFacture] = useState('');
const [totalHT, setTotalHT] = useState('');
const [totalTTC, setTotalTTC] = useState('');
const [TVA, setTVA] = useState('');
const [statut, setStatut] = useState('');

const [idFacture, setIdFacture] = useState('');

const newFacture = {
    libelle: libelle,
    client:client, 
    description:description,
    dateFacture:dateFacture,
    totalHT:totalHT,
    TVA:TVA,
    totalTTC:totalTTC,
};

const saveNewFacture = (e) => {
    e.preventDefault(); 
    setTypMsg('add')
    dispatch(onAddNewFacture(newFacture));
    
    setmodal_list(false);
    
 
  };

  // Mise a jour d"une Facture 
  const upFacture = { 
    libelle: libelle,
    client:client, 
    description:description,
    dateFacture:dateFacture,
    totalHT:totalHT,
    TVA:TVA,
    totalTTC:totalTTC, 
  };

  const saveUpdateFacture= (e) => {
    e.preventDefault();  
    dispatch(onUpdateFacture(idFacture,upFacture));
    setTypMsg('upd') 
    setmodal_update(false);
    
  };


  /// Suppression d'une facture
 

  const [listIdFacture, setListIdFacture] = useState([]);

  const idFactureChecBoxSelected = (factureId) => {
    setListIdFacture(prevSelectedRows => ({
      ...prevSelectedRows,
      [factureId]: !prevSelectedRows[factureId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdFacture)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const factureIds = Object.keys(filteredList);
 
  const deleteFacture = ( facture) => { 
    if(facture)dispatch(onDeleteFacture(facture));
    setTypMsg('del')
    if(listIdFacture && factureIds){factureIds.forEach((factureId) =>dispatch(onDeleteFacture(factureId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(factures.factures);


const dataTable= filteredData.length!==0 ? 
filteredData.map(facture => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    //console.log('xcate',categorie)
    const user = Object.values(users.users).find(us => us.id === facture.createdBy);
    const client = Object.values(clients.clients).find(prod => prod.id === facture.client);
    return { ...facture , user: user? user.prenom+' '+user.nom :'',clientInfo:client? client.nom:''  };
  }) 
  : 
  factures.factures.map(facture => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    const user = Object.values(users.users).find(us => us.id === facture.createdBy);
    const client = Object.values(clients.clients).find(prod => prod.id === facture.client);
    return { ...facture,user: user? user.prenom+' '+user.nom :'' ,clientInfo:client? client.nom:'' };
  });
//,categorieProd:categorie.libelle

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;

const handleSearch = (facture) => {
    const value = facture.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.libelle), _.toLower(value))||
      _.startsWith(_.toLower(item.client), _.toLower(value))|| 
      _.startsWith(_.toLower(item.description), _.toLower(value))||
      _.startsWith(_.toLower(item.dateFacture), _.toLower(value))||
      _.startsWith(_.toLower(item.totalHT), _.toLower(value))||
      _.startsWith(_.toLower(item.totalTTC), _.toLower(value))||  
      _.startsWith(_.toLower(item.TVA), _.toLower(value))||
      _.startsWith(_.toLower(item.statut), _.toLower(value))|| 

      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (facture, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


 
const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((facture,index) =>  (
    <tr key={facture.id}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idFactureChecBoxSelected(facture.id)}}   />
        </div>
    </th>
    <td className="facture_id" style={{ display: "none" }}>{facture.id}</td>
    <td className="facture_index">{index+1}</td>
    <td className="libelle">{facture.libelle}</td> 
    <td className="libelle">{facture.description}</td>
    <td className="client">{facture.clientInfo}</td>
    <td className="libelle">{facture.dateFacture}</td> 
    <td className="libelle">{facture.totalHT}</td> 
    <td className="libelle">{facture.TVA}</td> 
    <td className="libelle">{facture.totalTTC}</td> 
    <td className="libelle">{facture.statut}</td> 
    <td className="facture_date">{ facture.user}</td>
    <td className="facture_utilisateur">{formatedDate(facture.createdAt)}</td>
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setLibelle(facture.libelle);setDescription(facture.description);setDateFacture(facture.dateFacture);setTotalHT(facture.totalHT);setTVA(facture.TVA);setTotalTTC(facture.totalTTC);setClient(facture.client);setStatut(facture.statut);setIdFacture(facture.id);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteFacture(facture.id)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


));

   useEffect(() => { 
        
        dispatch(onGetFactures());
        dispatch(onGetUsers());
        dispatch(onGetClients());
        setFilteredData(factures.factures)
         
    },[dispatch,typMsg,onAddNewFacture,onUpdateFacture,onDeleteFacture]); 

   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Client" breadcrumbItem="Liste des clients" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deleteFacture('');setListIdFacture('')}}            
                                                    // onClick="deleteMultiple()"
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                </div> 
                                            </Col>
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="facture_id" onClick={(facture) => handleSort(facture, 'id')}>N°</th>
                                                        <th className="sort" data-sort="libelle" onClick={(facture) => handleSort(facture, 'libelle')}>Libelle</th>
                                                        
                                                        <th className="sort" data-sort="libelle" onClick={(facture) => handleSort(facture, 'libelle')}>Description</th>
                                                        <th className="sort" data-sort="client" onClick={(facture) => handleSort(facture, 'client')}>Client</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(facture) => handleSort(facture, 'libelle')}>Date Facture</th>
                                                        <th className="sort" data-sort="libelle" onClick={(facture) => handleSort(facture, 'libelle')}>Total HT</th>
                                                        <th className="sort" data-sort="libelle" onClick={(facture) => handleSort(facture, 'libelle')}>TVA</th>
                                                        <th className="sort" data-sort="libelle" onClick={(facture) => handleSort(facture, 'libelle')}>Total TTC</th>
                                                        <th className="sort" data-sort="libelle" onClick={(facture) => handleSort(facture, 'libelle')}>Statut</th>
                                                        <th className="sort" data-sort="createdBy" onClick={(facture) => handleSort(facture, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(facture) => handleSort(facture, 'createdAt')}>Date Création</th>
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Facture </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewFacture}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="libelle" className="form-label">Libelle</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  onChange={(e) => setLibelle(e.target.value)}/>
                            <label htmlFor="description" className="form-label">Description</label>
                            <input type="text" id="description" className="form-control" placeholder="Enter Description" required  onChange={(e) => setDescription(e.target.value)}/>
                            <label htmlFor="client" className="form-label">Client</label> 
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setClient(e.target.value)}  defaultValue="0">
                                <option value="">Sélectionnez un client</option> {/* Option vide par défaut */}
                                {Object.values(clients.clients).map((prod) => (
                                    <option key={prod.id} value={prod.id}>
                                    {prod.nom}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                            <label htmlFor="dateFacture" className="form-label">Date Facture</label>
                            <input type="date" id="dateFacture" className="form-control" placeholder="Enter Date Facture" required  onChange={(e) => setDateFacture(e.target.value)}/>
                           
                            <label htmlFor="totalHT" className="form-label">Montant HT</label>
                            <input type="decimal" id="totalHT" className="form-control" placeholder="Enter totalHT" required  onChange={(e) => setTotalHT(e.target.value)}/>
                           
                            <label htmlFor="libelle" className="form-label">Libelle</label>
                            <input type="decimal" id="TVA" className="form-control" placeholder="Enter TVA" required  onChange={(e) => setTVA(e.target.value)}/>
                           
                            <label htmlFor="totalTTC" className="form-label">totalTTC</label>
                            <input type="decimal" id="totalTTC" className="form-control" placeholder="Enter totalTTC" required  onChange={(e) => setTotalTTC(e.target.value)}/>
                           

                        </div>  
                         
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewFacture}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Facture </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateFacture}>
                    <ModalBody> 

                        <div className="mb-3">  <label htmlFor="libelle" className="form-label">Libelle</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required value={libelle}   onChange={(e) => setLibelle(e.target.value)}/>
                            <label htmlFor="description" className="form-label">Description</label>
                            <input type="text" id="description" className="form-control" placeholder="Enter Description" required value={description}  onChange={(e) => setDescription(e.target.value)}/>
                            <label htmlFor="client" className="form-label">Client</label> 
                           
                            <InputGroup>
                                <Input type="select" value={client} onChange={(e) => setClient(e.target.value)}  >
                                <option value="">Sélectionnez un client</option> {/* Option vide par défaut */}
                                {Object.values(clients.clients).map((prod) => (
                                    <option key={prod.id} value={prod.id}>
                                    {prod.nom}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>

                            <label htmlFor="dateFacture" className="form-label">Date Facture</label>
                            <input type="date" id="dateFacture" className="form-control" placeholder="Enter Date Facture" required value={dateFacture}  onChange={(e) => setDateFacture(e.target.value)}/>
                           
                            <label htmlFor="totalHT" className="form-label">Montant HT</label>
                            <input type="decimal" id="totalHT" className="form-control" placeholder="Enter totalHT" required value={totalHT}   onChange={(e) => setTotalHT(e.target.value)}/>
                           
                            <label htmlFor="libelle" className="form-label">Libelle</label>
                            <input type="decimal" id="TVA" className="form-control" placeholder="Enter TVA" required value={TVA}   onChange={(e) => setTVA(e.target.value)}/>
                           
                            <label htmlFor="totalTTC" className="form-label">totalTTC</label>
                            <input type="decimal" id="totalTTC" className="form-control" placeholder="Enter totalTTC" required  value={totalTTC}  onChange={(e) => setTotalTTC(e.target.value)}/>
                           
 
                            
                           
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateFacture}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Factures;
